import httpClient from "../config/httpClient";
import { BaseApi } from "../config";
export const getVerticalGroupOptions = async () => {
    try {
        const resp = await httpClient.get(`${BaseApi}/leads/verticalgroup/`)
        return resp.data;
    } catch (e) {
        console.error(e)
        return []
    }
    return null
} 

export const getVerticalGroupOptionsByUser = async () => {
    try {
        const resp = await httpClient.get(`${BaseApi}/@me`)
        return resp.data.verticals;
    } catch (e) {
        console.error(e)
    }
    return null
} 

