import React, { useState } from "react";
import { disposedLeadSurveyOptions } from "../../helpers/constantData";
import { MDBBtn } from "mdb-react-ui-kit";

export const Confirm = ({leadStatus, handleStatusChange}) => {
    const [survey, setSurvey] = useState('')
    const [surveyDescription, setSurveyDescription] = useState("")
    const [loadingLead, setLoadingLead] = useState(false)
    const handleConfirm = async () => {
        setLoadingLead(true)
        await handleStatusChange(leadStatus, survey, surveyDescription)
        setLoadingLead(false)
        setSurvey('')
        setSurveyDescription('')
    }

    return (
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
            {
                leadStatus == "2"?
                <>
                    <span className="text-center">By marking this lead as booked you confirm that this lead has converted and consent to be charged by Lead Depot in accordance with the previously accepted lead terms.</span>
                </>
                :
                <>
                    <span className="text-center">By returning this lead you attest that despite contacting the consumer and attempting to convert this lead to the best of your ability no agreement was reached.</span>
                    <select value={survey} onChange={(e) => setSurvey(e.target.value) } className="form-select">
                        {
                            disposedLeadSurveyOptions.map(option => (
                                <option value={option.value}>{option.label}</option>
                            ))
                        }
                    </select>
                    {
                        survey === "other"?
                        <input placeholder='Other...' className='form-control mt-2' value={surveyDescription} onChange={(e) => setSurveyDescription(e.target.value)} type="text" />
                        :
                        null
                    }
                </>
            }
            {
                loadingLead?
                <MDBBtn  disabled color="dark" size="lg">Confirm</MDBBtn>
                :
                <MDBBtn color="dark" size="lg" onClick={() => handleConfirm()}>Confirm</MDBBtn>
            }
        </div>
    )
};

export default Confirm;