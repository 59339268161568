import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
}
from 'mdb-react-ui-kit';
import { Multiselect } from '../../components/Multiselect';
import { useNavigate } from 'react-router-dom';
import { getVerticalGroupOptions } from '../../api/verticalApi';
import httpClient from '../../config/httpClient';
import { BaseApi } from '../../config';
import logo from '../../assets/images/logo.svg';
import cardImage from '../../assets/images/contractorMixer.jpg'
import { Modal, Row, Col, Form, Button, Breadcrumb } from 'react-bootstrap';
import { inputRange, formatPhoneNumber, getNumbersFromString } from '../../helpers/inputHelpers';
import ValidateInput from '../../components/ValidateInputs';
import { emptyString, isValidZip, isValidEmail } from '../../helpers/validationHelpers';
import Skeleton from 'react-loading-skeleton';
import { usStates } from '../../helpers/constantData';

export const UserRegistrationPage = () => {

    const [options, setOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [verticals, setVerticals] = useState([]);
    const [invalidVerticals, setInvalidVerticals] = useState(false);
    const [states, setStates] = useState([])
    const [invalidStates, setInvalidStates] = useState(false);
    const [email, setEmail] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [password, setPassword] = useState("")
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [invalidPasswordConfirm, setInvalidPasswordConfirm] = useState(false);
    const[contactFN, setFN] = useState("")
    const [invalidContactFN, setInvalidContactFN] = useState(false);
    const[contactLN, setLN] = useState("")
    const [invalidContactLN, setInvalidContactLN] = useState(false);
    const[radius, setRadius] = useState("")
    const [invalidRadius, setInvalidRadius] = useState(false)
    const[phoneNumber, setPhone] = useState("")
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const[searchZip, setZip] = useState('')
    const [invalidZipcode, setInvalidZipcode] = useState(false)
    const [show, setShow] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const navigate = useNavigate()
    
    const setInvalidations = async () => {
        if (
            emptyString(email) || emptyString(password) || emptyString(contactFN) || 
            emptyString(contactLN) || !verticals.length || emptyString(radius) ||
            !isValidEmail(email) || !isValidZip(searchZip) || emptyString(phoneNumber)
            || emptyString(passwordConfirm) || password !== passwordConfirm
            || phoneNumber.length < 10 || !states.length
            ) {
            setShow(true)
            if (!isValidEmail(email)) setInvalidEmail(true)
            if (emptyString(password)) setInvalidPassword(true)
            if (password !== passwordConfirm) setInvalidPasswordConfirm(true)
            if (emptyString(contactFN)) setInvalidContactFN(true)
            if (emptyString(contactLN)) setInvalidContactLN(true)
            if(emptyString(radius)) setInvalidRadius(true)
            if(!isValidZip(searchZip)) setInvalidZipcode(true)
            if(!verticals.length) setInvalidVerticals(true)
            if(!states.length) setInvalidStates(true)
            if (phoneNumber.length < 10) setInvalidPhoneNumber(true)
            setLoadingRegister(false);
            return true
        }
        
        return await verifyPhoneNumber()
    }

    const verifyPhoneNumber = async () => {
        const response = await httpClient.post(`${BaseApi}/verify_phone_number/`, {
            phoneNumber: formatPhoneNumber(phoneNumber),
        })
        if(response.data.error) {
        setInvalidPhoneNumber(true)
        setLoadingRegister(false);
        return true
        } else return false
    }

    const registerUser = async () => {
    if (await setInvalidations()) return;
    try {
        const response = await httpClient.post(`${BaseApi}/register/user/`, {
        email,
        password,
        contactFN,
        contactLN,
        verticals: verticals.map(v => v.value).join(","),
        statesLicensed: states.map(state => state.value).join(','),
        radius,
        phoneNumber: formatPhoneNumber(phoneNumber),
        searchZip
        });
        localStorage.setItem("token_user", response.data.token);
        localStorage.setItem('howItWorksVideo', true)
        navigate('/register2', {replace: true})
    } catch (error) {
        if (error.response.status === 401) {
        alert("Invalid credentials");
        }
        if (error.response.status === 409) {
            alert("User Already exists!")
            setLoadingRegister(false)
        }
    } 
    };

    const handleFirstName = (fn) => {
        setFN(fn);
        setInvalidContactFN(false);
    }

    const handleLastName = (ln) => {
        setLN(ln);
        setInvalidContactLN(false);
    }

    const handleRadius = (rad) => {
        setInvalidRadius(false)
        if(parseInt(rad) < 1) return setRadius(1);
        if (parseInt(rad) > 1000) alert('The maximum radius is 1000 miles')
        setRadius(inputRange(rad, 0, 1000))
    }

    const handleZip = (zipcde) => {
        if (zipcde.length <=5) setZip(zipcde)
        setInvalidZipcode(false)
    }
    
    const handlePhone = (phone) => {
        if (getNumbersFromString(phone).length > 10) return
        setPhone(phone)
        setInvalidPhoneNumber(false);
    }

    const handleVerticals = (verts) => {
        setVerticals(verts);
        setInvalidVerticals(false);
    }

    const handleStates = (sts) => {
        setStates(sts);
        setInvalidStates(false);
    }

    const handleEmail = (mail) => {
        setEmail(mail);
        setInvalidEmail(false);
    }

    const handlePassword = (psw) => {
        setPassword(psw);
        setInvalidPassword(false)
    }

    const handlePasswordConfirm = (pswConfirm) => {
        setPasswordConfirm(pswConfirm);
        setInvalidPasswordConfirm(false);
    }

    useEffect(() => {
        (async() => {
            setLoadingOptions(true);
            const data = await getVerticalGroupOptions();
            setOptions(data.data.map((option) => ({
                label: option.groupName,
                value: option.groupID,
                
            })))
            setLoadingOptions(false);
        })();
        if (localStorage.getItem("radius") && localStorage.getItem("radius") !== "null") setRadius(localStorage.getItem("radius"))
        if (localStorage.getItem("zip") && localStorage.getItem("zip") !== "null") setZip(localStorage.getItem("zip"))
        // if (localStorage.getItem("verticals")) 
    }, [])

    useEffect(() => {
        if(loadingRegister) registerUser()
    }, [loadingRegister])
    
    return(
        <MDBContainer className="my-5">

        <MDBCard>
        <MDBRow className='g-0'>

            <MDBCol md='6'>
            <MDBCardImage src={cardImage} alt="login form" className='d-md-block d-none rounded-start w-100'/>
            </MDBCol>
    
            <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>
                <div>
                <Breadcrumb>
                    <Breadcrumb.Item> User Information </Breadcrumb.Item>
                    <Breadcrumb.Item active> Company Information </Breadcrumb.Item>
                    <Breadcrumb.Item active> Terms and Conditions </Breadcrumb.Item>
                </Breadcrumb>
                </div>  
    
                <div className='d-flex flex-row mt-2'>
                <img
                    src={logo}
                    width = "5000"
                    height ="100"
                    className="d-inline-block align-top"
                    alt="Lead Depot Logo"
                />
                </div>

                <br/><br/>

                <Form>
                    <Row>
                    <Col>
                        <ValidateInput 
                            value={contactFN} 
                            onChange={handleFirstName} 
                            invalid={invalidContactFN} 
                            label="First Name" 
                            feedback="Please provide a first name" 
                        />                        
                    </Col>
                    <Col>
                        <ValidateInput
                            value={contactLN}
                            onChange={handleLastName}
                            invalid={invalidContactLN}
                            label='Last Name'
                            feedback='Please provide a last name'
                        />
                    </Col>
                    </Row>
                <Form.Group>
                    <Row>
                    <Col md={8}>
                        <Form.Label>Services Provided</Form.Label>
                        {   
                            loadingOptions?
                                <Skeleton height={50} width={"100%"} />
                                :
                                <Multiselect 
                                    value={verticals} 
                                    options={options} 
                                    onChange={handleVerticals}
                                />
                        }
                        {
                            invalidVerticals?
                                <div 
                                    style={{ display: 'block', position: 'relative', zIndex: 2 }} 
                                    className="invalid-feedback mt-1"
                                >
                                    Please select at least one service type
                                </div>
                                :
                                null
                        }
                    </Col>  
                    <Col className='mt-2' md={8}>
                        <Form.Label>States Served</Form.Label>
                        {   
                            
                            <Multiselect 
                                value={states} 
                                options={usStates} 
                                onChange={handleStates}
                                displayMessage={'State'}
                            />
                        }
                        {
                            invalidStates?
                                <div 
                                    style={{ display: 'block', position: 'relative', zIndex: 2 }} 
                                    className="invalid-feedback mt-1"
                                >
                                    Please select at least one state
                                </div>
                                :
                                null
                        }
                    </Col>  
                    <Col>
                        <Form.Label>Service Radius</Form.Label>
                        <ValidateInput 
                            value={radius}
                            onChange={handleRadius}
                            invalid={invalidRadius}
                            label="Radius"
                            feedback="Please provide a service radius"
                            type="number"
                            min="0"
                            max="1000"
                        />        
                    </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                    <Col md={8}>
                        <ValidateInput
                            value={phoneNumber}
                            onChange={handlePhone}
                            invalid={invalidPhoneNumber}
                            label='Mobile Phone'
                            feedback="Please provide a valid phone number"
                        />
                    </Col>
                    <Col>
                        <ValidateInput
                            value={searchZip} 
                            onChange={handleZip}
                            invalid={invalidZipcode}
                            label="Zipcode"
                            feedback="Please provide a valid zipcode"
                        />
                    </Col>
                    </Row>                  
                </Form.Group>


                <Form.Group>
                        <ValidateInput
                            value={email}
                            onChange={handleEmail}
                            invalid={invalidEmail}
                            label="Email"
                            type="email"
                            feedback="Please provide a valid email address"
                        />
                </Form.Group>

                <Form.Group>
                        <ValidateInput
                            value={password}
                            onChange={handlePassword}
                            invalid={invalidPassword}
                            label="Password"
                            type="password"
                            feedback="Please provide a valid password"
                        />
                </Form.Group>
                
                <Form.Group>
                        <ValidateInput
                            value={passwordConfirm}
                            onChange={handlePasswordConfirm}
                            invalid={invalidPasswordConfirm}
                            label='Confirm Password'
                            type="password"
                            feedback="Password entries do not match"
                        />
                </Form.Group>

                <br/>

                <Form.Group>
                    <div className="d-grid gap-2">
                    <Button disabled={loadingRegister} variant="dark" size="lg" onClick={() => setLoadingRegister(true)}>
                        Register
                    </Button>
                    </div>
                </Form.Group>
                </Form>

                <br/>

                <div className='d-flex flex-row justify-content-start'>
                <a href="https://leaddepot.com/terms-and-conditions/" className="small text-muted me-1">Terms of use.</a>
                <a href="https://leaddepot.com/privacy-policy/" className="small text-muted">Privacy policy</a>
                </div>
    
            </MDBCardBody>
            </MDBCol>
    
        </MDBRow>
        </MDBCard>
    </MDBContainer>
    )
}

export default UserRegistrationPage;