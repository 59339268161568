import React, {useState, useEffect} from "react";
import RecoverPasswordEmailInput from "../../components/RecoverPasswordEmailInput";
import RecoverPasswordNew from "../../components/RecoverPasswordNew";
import LeadsLoader from "../../components/LeadsLoader";
import { useParams } from "react-router-dom";
export const RecoverPassword = () => {
    const params = useParams();
    const [link, setLink] = useState(null)
    const [load, setLoad] = useState(true)

    useEffect(()=> {
        if (params.link) setLink(params.link)
        setLoad(false)
    }, [])

   return (
    <>
        {
            load?
            <LeadsLoader />
            :
            link?
            <RecoverPasswordNew token={link} />
            :
            <RecoverPasswordEmailInput />
        }
    </>
   )
}
export default RecoverPassword;