export const inputRange = (value, minRange, maxRange) => {
    if (parseInt(value) > maxRange) return `${maxRange}`
    if (parseInt(value) < minRange) return `${minRange}`
    return value
}

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = getNumbersFromString(phoneNumberString);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); 
    if (phoneNumberString.length<=3) return `(${cleaned})`   
    
    if (phoneNumberString.length<=6) 
        return `(${cleaned.slice(0,3)}) ${cleaned.slice(3)}`
    if (phoneNumberString.length <10) 
        return `(${cleaned.slice(0,3)}) ${cleaned.slice(3)}-${cleaned.slice(6)}`
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  }

export const getNumbersFromString = (str) => str.replace(/\D/g, "");