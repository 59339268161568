import React from "react";
import BookedIcon  from "../../assets/icons/bookedIcon.png"
import IconCarpentry  from "../../assets/icons/Icon_Carpentry.png";
import IconCleaning  from "../../assets/icons/Icon_Cleaning.png";
import IconConcrete  from "../../assets/icons/Icon_Concrete.png";
import IconDoorAndWindow  from "../../assets/icons/Icon_DoorAndWindow.png";
import IconElectrical  from "../../assets/icons/Icon_Electrical.png";
import IconExtermination  from "../../assets/icons/Icon_Extermination.png";
import IconFencing  from "../../assets/icons/Icon_Fencing.png";
import IconFlooring  from "../../assets/icons/Icon_Flooring.png";
import IconGarageDoor  from "../../assets/icons/Icon_GarageDoor.png";
import IconHandyMan  from "../../assets/icons/Icon_Handyman.png"
import IconGeneralHomeServices from "../../assets/icons/Icon_GeneralHomeServices.png"
import IconHomeSecurity  from "../../assets/icons/Icon_HomeSecurity.png"
import IconHVAC from "../../assets/icons/Icon_HVAC.png"
import IconLandscaping from "../../assets/icons/Icon_Landscaping.png"
import IconLocksmithing from "../../assets/icons/Icon_Locksmithing.png"
import IconMoving from "../../assets/icons/Icon_Moving.png"
import IconMovingLocal from "../../assets/icons/Icon_MovingLocal.png"
import IconMovingLongDistance from "../../assets/icons/Icon_MovingLongDistance.png"
import IconPainting from "../../assets/icons/Icon_Painting.png"
import IconPlumbing from "../../assets/icons/Icon_Plumbing.png"
import IconRemodeling from "../../assets/icons/Icon_Remodeling.png"
import IconRoofing from "../../assets/icons/Icon_Roofing.png";
import IconSiding from "../../assets/icons/Icon_Siding.png";
import IconSolar from "../../assets/icons/Icon_Solar.png";
import IconTiling from "../../assets/icons/Icon_Tiling.png"
import stylesTable from './leadsIconTable.module.css'


export const LeadsIcon = ({icon, width, height, css = ''}) => {
    const icons = {
        ['BookedIcon'] : BookedIcon,
        ['Icon_Carpentry']: IconCarpentry,
        ['Icon_Cleaning']: IconCleaning,
        ['Icon_Concrete']: IconConcrete,
        ['Icon_DoorAndWindow']: IconDoorAndWindow,
        ['Icon_Electrical']: IconElectrical,
        ['Icon_Extermination']: IconExtermination,
        ['Icon_Fencing']: IconFencing,
        ['Icon_Flooring']: IconFlooring,
        ['Icon_GarageDoor']: IconGarageDoor,
        ['icon_GarageDoor']: IconGarageDoor,
        ['Icon_Handyman']: IconHandyMan,
        ['Icon_GeneralHomeServices']: IconGeneralHomeServices,
        ['Icon_HomeSecurity']: IconHomeSecurity,
        ['Icon_HVAC']: IconHVAC,
        ['Icon_Landscaping']: IconLandscaping,
        ['Icon_Locksmithing']: IconLocksmithing,
        ['Icon_Moving']: IconMoving,
        ['Icon_MovingLocal']: IconMovingLocal,
        ['Icon_MovingLongDistance']: IconMovingLongDistance,
        ['Icon_Painting']: IconPainting,
        ['Icon_Plumbing']: IconPlumbing,
        ['Icon_Remodeling']: IconRemodeling,
        ['Icon_Roofing']: IconRoofing,
        ['Icon_Siding']: IconSiding,
        ['Icon_Solar']: IconSolar,
        ['Icon_Tiling']: IconTiling,
    }
    const getCss = () => {
        const cssSheet = css.split('-')
        if (cssSheet[0] == 'tableLeadboard') {
            if (cssSheet.length > 1) return stylesTable.disabledIcon
            return stylesTable.icon
        }
    } 
    return <img src={icons[icon]} className={css? getCss() : ''} style={css? {}: {width, height}} />
}

export default LeadsIcon;