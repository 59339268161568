import React, { useState, useEffect } from "react";
import { Tab, Tabs, Card, Modal } from "react-bootstrap";
import UserProfile from "../../components/UserProfile";
import CompanyProfile from "../../components/CompanyProfile";
import ManageBilling from "../../components/ManageBilling";
import {useStripe} from '@stripe/react-stripe-js';
import { getBillingDetails, AuthorizeDefaultDeleting, authorizePaymentCards } from "../../api/billingApi";
import Skeleton from "react-loading-skeleton";
import LeadsLoader from "../../components/LeadsLoader";
export const UserPage = () => {
    const [selectedKey, setSelectedKey] = useState();
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [refreshCards, setRefreshCards] = useState(false)
    const [open, setOpen] = useState(false)
    const stripe = useStripe();
    const handleSelect = async (key) => {
      setSelectedKey(key);
      // if (key === "billing") {
      //   const resp = await getBillingDetails();
      //   // window.open(resp.url, "_blank");
      //   window.location.href=resp.url
      //   setSelectedKey("user")
      // }
    }
    useEffect(() => {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
      );
      if(clientSecret && (localStorage.getItem('openForDeleted') || localStorage.getItem('managePaymentMethod'))) {
        console.log('not open')
        setOpen(true)
        setLoading(true)
      }
    }, [])
    useEffect(() => {
      if (!stripe) {
        return;
      }
      handleStatus();
        }, [stripe]);
    const handleStatus = async () => {
      const clientSecret = new URLSearchParams(window.location.search).get(
          'setup_intent_client_secret'
        );
        if(!clientSecret || !(localStorage.getItem('openForDeleted') || localStorage.getItem('managePaymentMethod'))) return
        console.log(clientSecret);
        // Retrieve the SetupIntent
        const setupIntent = await stripe.retrieveSetupIntent(clientSecret)
        setLoading(true)
        switch (setupIntent.setupIntent.status) {
          case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            break;
            
            case 'processing':
              setMessage("Processing payment details. We'll update you when processing is complete.");
              break;
  
          case 'requires_payment_method':
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              setMessage('Failed to process payment details. Please try another payment method.');
              break;
          }
          
          if (localStorage.getItem('openForDeleted')) {
            setOpen(true)
            const response = await AuthorizeDefaultDeleting()
            localStorage.setItem('authorize', response.authorize)
            localStorage.setItem('managePaymentMethodAfterOpenForDeleted', true)
            localStorage.removeItem('openForDeleted')
            setOpen(false)
            setSelectedKey('billing')
            setRefreshCards(true)
            setLoading(false)
            return 
        }
        if (localStorage.getItem('managePaymentMethod')) {
          setOpen(true)
          localStorage.removeItem('managePaymentMethod')
          await authorizePaymentCards();
          setSelectedKey('billing')
          setOpen(false)
        }
        setLoading(false)
      }

    return (
      <>
        <div className="container py-3">
          {
            loading?
            <LeadsLoader />
            :
            <Card>
              <Card.Body>
                <h1 className="h1">Edit Profile</h1>
                <Tabs 
                  id="controlled-tab-example"
                  activeKey={selectedKey}
                  onSelect={handleSelect}
                  className="mb-3"
                  >  
                  <Tab eventKey="user" title="user">
                      <UserProfile />
                  </Tab>
                  <Tab eventKey="company" title="company">
                      <CompanyProfile /> 
                  </Tab>
                  <Tab eventKey="billing" title="billing">
                    <ManageBilling refreshCards={refreshCards} setRefreshCards={setRefreshCards} />
                  </Tab>
              </Tabs>
          </Card.Body>
      </Card>
          }
      </div>
        <Modal show={open} onHide={() => setOpen(false)}>
          <Modal.Body>
            <LeadsLoader />
            <span className='text-center d-flex align-items-center justify-content-center'>
            Please do not navigate away while we authorize your card, doing so may result in a duplicate authorization.
            </span>
          </Modal.Body>
        </Modal>
      </>
  )
}

export default UserPage;