import React, { useState, useEffect, useRef} from 'react';
import logo from '../../assets/images/logo.svg';
import { Link } from "react-router-dom";
import { Outlet } from 'react-router';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate, useLocation } from 'react-router';
import { routes } from '../../containers/Routing';
import HowItWorks from '../HowItWorks';
import SupportContact from '../SupportContact';
import './Nav.css';

function clickOutside(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
const NavPage = () => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null)
  const ref = useRef();
  const navigate = useNavigate()
  const location = useLocation()
  const handleDisplay = (e) => {
    e.preventDefault();
    setShow(!show);
  }
  useEffect(() => {
    setUser(localStorage.getItem("user"))
  }, [])
  const handleLogout = async () => {
    localStorage.removeItem("token_user");
    localStorage.removeItem("user");    
    localStorage.removeItem("verticals");
    localStorage.removeItem("radius");
    localStorage.removeItem("zip");
    localStorage.removeItem("leadClaimed");
    localStorage.removeItem('companyRegistered');
    localStorage.removeItem('acceptedTNC');
    navigate('/login', {replace: true});
  }

  clickOutside(ref, () => setShow(false))

    return(
      <>
        <nav ref={ref} className="navbar navbar-expand-lg bg-light" style={{zIndex: 1000}}>
          <div className="container-fluid px-lg-5 bg-light bg-lg-transparent">
            <span className="navbar-brand ms-3 ms-lg-0">
              <Link to='/leadboard'>
                <img
                    src={logo}
                    width = "100"
                    height ="50"
                    className="d-inline-block align-top"
                    alt="Lead Depot Logo"
                  />
              </Link>
            </span>
            <button className="navbar-toggler" type="button" onClick={(e) => handleDisplay(e)}>
              <MDBIcon fas icon="bars" />
            </button>
            <div className={`collapse navbar-collapse ${show? 'show': ''}`}>
              <ul className="navbar-nav mr-auto mb-2 mb-lg-0 p-lg-0 px-3 bg-light bg-lg-transparent">
                {
                  routes.filter(route => route.navItem).map(route => (
                    route.name == "My Leads"? 
                    user?
                    <li className="nav-item" >
                      <Link onClick={() => setShow(false)} style={{color: location.pathname == route.path? "#0e52c1": ""}} className="nav-link active" to={route.path}>
                        {route.name}
                      </Link>
                    </li>:
                    null:
                    <li className="nav-item">
                      <Link onClick={() => setShow(false)} className="nav-link selected"  style={{color: location.pathname == route.path? "#0e52c1": ""}} to={route.path}>
                        {route.name}
                      </Link>
                    </li>
                  ))
                }
               {user? 
               <>
                <li className="nav-item" aria-selected>
                  <Link onClick={() => setShow(false)} style={{color: location.pathname == "/user"? "#0e52c1": ""}}className="nav-link selected" to="/user">
                    <span>My Account</span>
                  </Link>
                  </li>
                 
                </>
                :  <HowItWorks />
                }
              </ul>
              {user?
                <>
                <SupportContact />
              <span className='px-3 p-lg-0 signout-btn' onClick={() => handleLogout()}>
                <MDBIcon fas icon="sign-out-alt" />
                </span>
                </>
                :
                <Link className="nav-link" to="/login">
                  <span>Login</span>
                </Link>
              }
            </div>
          </div>
        </nav>
        </>
    )
};

export const WithoutNav = () => {
  return (
    <Outlet />
  )
}

export const WithNav = () => {
  return (
    <>
      <NavPage />
      <Outlet />
    </>
  )
}