import React, { useEffect, useState } from 'react';
import { Container,Form, Row, Col } from 'react-bootstrap/';
import { 
    getMyLeadDetail, 
    getLeadsMyLeads, 
    getClosedLeads,
    getClosedLeadDetail,
    getReminderLeadIDToken
} from '../../api/leadsApi';
import { useSearchParams } from 'react-router-dom';
import MyLeadsModal from '../../components/MyLeadsModal';
import  MyLeadsTable from '../../components/MyLeadsTable';
import { sortLeadAgeAsc, sortLeadAgeDesc, sortLeadDistAsc, sortLeadDistDesc } from '../../helpers/sortingHelpers'; 
import { useJsApiLoader, GoogleMap, Marker  } from '@react-google-maps/api';
import { sortOptions } from '../../helpers/constantData';
import DownloadMyLeads from '../../components/DownloadMyLeads';
import Skeleton from 'react-loading-skeleton';
import moment from "moment";
import "./MyLeads.css"

export const MyLeads = () => {
    const [sort, setSort] = useState(sortOptions.age.asc)
    const [googleMap, setGoogleMap] = useState(null)
    const [defaultLoadMap, setDefaultLoadMap] = useState(false);
    const [zipcode, setZipcode] = useState('')
    const [defaultLoad, setDefaultLoad] = useState(false)
    const [closedLeads, setClosedLeads] = useState(false);
    const [markerAddresses, setMarkerAddresses] = useState([])
    const [markers, setMarkers] = useState([]);
    const [perimeter, setPerimeter] = useState(false)
    const [center, setCenter] = useState({});
    const [leadInfo, setLeadInfo] = useState(null)
    const [leadIDTitle, setLeadIDTitle] = useState("");
    const [leadIcon, setLeadIcon] = useState("");
    const [loadingLead, setLoadingLead] = useState(false)
    const [leads, setLeads] = useState([]);
    const [leadID, setLeadID] = useState('');
    const [loadingLeads, setLoadingLeads] = useState(false)
    const [show, setShow] = useState(false);
    
    const handleShow = () => setShow(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBxAEFQuErKxlK8PJPAsdkLeOoHHwcBd_o',
        libraries: ['places']
    })

    const setUserValues = async () => {
        if (!localStorage.getItem("user")) return
        const user = JSON.parse(localStorage.getItem("user"))
        if(!zipcode) setZipcode(user.searchZip)
        setDefaultLoad(true);
    }

    const sortingData = (data) => {
        if (sort == sortOptions.age.desc) return data.sort(sortLeadAgeDesc)
        if (sort == sortOptions.age.asc) return data.sort(sortLeadAgeAsc)
        if (sort == sortOptions.dist.asc) return data.sort(sortLeadDistAsc)
        if (sort == sortOptions.dist.desc) return data.sort(sortLeadDistDesc)
        return data
    }

    const getDisposableDays = (day) => {
        const result = 14 - day
        if (result <= 0) return 0
        return result
    }

    const getActiveLeads = async () => {
        try {
            setLoadingLeads(true);
            
            const response = closedLeads? await  getClosedLeads(zipcode) : await getLeadsMyLeads(zipcode)
            setLeads(sortingData(response.data.map((ld) => {
                ld = {...ld, leadDate: getDisposableDays(parseInt(moment().diff(moment(ld.transactionDate), "days"))) }
                return {...ld}
            })))
            setMarkerAddresses(response.data.map((ld) => `${ld.city}-${ld.usState}-${ld.zipcode}`))
            setLoadingLeads(false);

        } catch (e) {
            console.log(e);
            setLoadingLeads(false);
        }
    }

    const settingMarkers = () => {
        const markersAux = []
        for (const markerAddress of markerAddresses) {
            fetch(`https://api.sharpcloud.io/geocode/?address=${markerAddress}&apiKey=si_Mw88iYDXof9jEe`)
                .then(response => response.json())
                .then(data => {
                    const position = {
                        lat: Number(data.lat),
                        lng: Number(data.lon)
                    };
                    markersAux.push(position)
                            const infowindow = new window.google.maps.InfoWindow({
                                content: markerAddress,
                                ariaLabel: markerAddress,
                    });
                    const marker = new window.google.maps.Marker({
                        position,
                        title: markerAddress,
                    });
                    marker.setMap(googleMap)
                    marker.addListener("click", () => {
                        infowindow.open({
                            anchor: marker,
                            map: googleMap
                        })
                    })
                })
                .catch(error => {
                    alert(`Geocoding was not successful for the address: ${markerAddress}`);
                });
        }
        setMarkers(markersAux)
    }
    
    useEffect(() => {
       getActiveLeads()
    }, [closedLeads])

    useEffect(() => {
        if (perimeter && markerAddresses.length > 0) settingMarkers();
    }, [perimeter, markerAddresses])
        
    useEffect(() => {
        const leadIDParam = searchParams.get("leadIDReminder")
        if (leadIDParam) setReminderLead()
        setUserValues();
        if (localStorage.getItem("MyLead" && !leadIDParam) && localStorage.getItem("MyLead") !== "null") setDefaultLead()
        getActiveLeads();
    },[])

    const setDefaultLead = async () => {
        const MyLead = JSON.parse(localStorage.getItem("MyLead"))
        setLoadingLead(true)
        setLeadIDTitle(MyLead.leadIDTitle);
        setLeadIcon(MyLead.leadIcon);
        handleShow()
        const response = await getMyLeadDetail(MyLead.leadID);
        let leadData ={ ...response.data, transactionDate: getDisposableDays(parseInt(moment().diff(moment(MyLead.transactionDate), "days"))) }
        if (response.data) setLeadInfo(leadData)
        setLoadingLead(false)
    }

    const setReminderLead = async () => {
        const leadIDReminder = searchParams.get("leadIDReminder")
        const response = await getReminderLeadIDToken({leadIDReminder})
        if (response.error) console.log('Seteando error');
        if (response.data) {
            setLeadIDTitle(response.data.leadID.slice(-5))
            setLeadIcon(response.data.iconName);
            setLeadInfo({...response.data, transactionDate: response.data.disposableDays})
            handleShow()
            setLoadingLead(false)
        }
        }
    const handleAddress = () => {
        const geocoder = new window.google.maps.Geocoder();
        setDefaultLoadMap(false)
        setPerimeter(false);
        geocoder.geocode({
            'address': zipcode,
            "componentRestrictions":{"country":"US"}
        }, (results, status) => {
            if (status == window.google.maps.GeocoderStatus.OK) {
                const position = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                }
                googleMap.panTo(position)
                googleMap.setCenter(position)
                setCenter(() => position)
                setPerimeter(true);
                setDefaultLoadMap(true)
        } else {
                alert("Geocode was not successful for the following reason: " + status);
        }
        })
        
    }
    

    useEffect(() => {
        if (sort && leads.length) setLeads(sortingData(leads))
    }, [sort])

    const handleDetails = async(lead) => {
        setLoadingLead(true)
        const {leadID, iconName} = lead
        setLeadIDTitle(leadID.slice(-5));
        setLeadIcon(iconName);
        handleShow()
        const response = closedLeads? await getClosedLeadDetail(leadID) : await getMyLeadDetail(leadID);
        let leadData ={ ...response.data, transactionDate: getDisposableDays(parseInt(moment().diff(moment(lead.transactionDate), "days"))) }
        if (response.data) setLeadInfo(leadData)
        setLoadingLead(false)
    }

    useEffect(()=> {
        if (!defaultLoadMap && perimeter && zipcode) handleAddress();
    }, [zipcode, perimeter]);


    const onLoad = React.useCallback((map) => {;
        setGoogleMap(map);
        const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({
                'address': zipcode,
                "componentRestrictions":{"country":"US"}
            }, (results, status) => {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    const position = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    }
                    map.panTo(position)
                    setCenter(position)
                    setPerimeter(true);
                ;
            } else {
                    alert("Geocode was not successful for the following reason: " + status);
            }
            })
      }, [])

      if (!isLoaded) {
        return (
            <div className='container-fluid'>
                <Skeleton height={'90vh'}/>
            </div>
        );
    }

    return (
        <Container fluid className='overflow-hidden overflow-md-auto'>
          <Row>
                    <Col className='MapBox col-my-leads d-none d-lg-block'>
                        <GoogleMap
                            center={center}
                            zoom={9}
                            mapContainerStyle={{ width: '100%', height: '100%'}}
                            onLoad={onLoad}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false
                            }}>
                            {
                                markers.length?
                                markers.map((mark) => (
                                    <Marker
                                        position={mark}
                                    />
                                ))
                                :
                                null
                            }
                           
                        </GoogleMap>
                    
                    </Col>
                    <Col className='tableColumn col-my-leads col-lg-9'> 
                    <Form>
                        <div style={{height: "10vh", backgroundColor:"rgb(247, 250, 252)" }} className='d-flex align-items-center justify-content-endp px-3'>

                                <select className='form-select' onChange={() => setClosedLeads(!closedLeads)}>
                                    <option>Claimed Leads</option>
                                    <option>Closed Leads</option>
                                </select>

                                
                            
                            <DownloadMyLeads closedLeads={closedLeads} />
                        </div>
                    </Form>
                    <MyLeadsTable 
                        leads={leads}
                        loadingLeads={loadingLeads}
                        sort={sort}
                        setSort={setSort}
                        handleDetails={handleDetails}
                    />
                    </Col>
                </Row>
                <MyLeadsModal 
                    show={show}
                    setShow={setShow}
                    loadingLead={loadingLead}
                    leadInfo={leadInfo}
                    leadIcon={leadIcon}
                    leadID={leadID}
                    leadIDTitle={leadIDTitle}
                    getActiveLeads={getActiveLeads}
                    closedLeads={closedLeads}

                />
        </Container>
    );
}

export default MyLeads;