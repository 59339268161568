export const sortLeadAgeDesc = (lead1, lead2) => {
    if (parseInt(lead1.leadDate) < parseInt(lead2.leadDate)) return 1;
    if (parseInt(lead1.leadDate) > parseInt(lead2.leadDate)) return -1;
    return 0
}

export const sortLeadAgeAsc = (lead1, lead2) => {
    if (parseInt(lead1.leadDate) > parseInt(lead2.leadDate)) return 1;
    if (parseInt(lead1.leadDate) < parseInt(lead2.leadDate)) return -1;
    return 0
}

export const sortLeadDistAsc = (lead1, lead2) => {
    if (parseFloat(lead1.distance) < parseFloat(lead2.distance)) return 1;
    if (parseFloat(lead1.distance) > parseFloat(lead2.distance)) return -1
    return 0   
}

export const sortLeadDistDesc = (lead1, lead2) => {
    if (parseFloat(lead1.distance) > parseFloat(lead2.distance)) return 1;
    if (parseFloat(lead1.distance) < parseFloat(lead2.distance)) return -1
    return 0
}