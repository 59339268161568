export const sortOptions = {
    age: {
        asc: "sortAgeAsc",
        desc: "sortAgeDesc"
    },
    dist: {
        asc: "sortDistAsc",
        desc: "sortDistDesc"
    }
}

export const statusOptions = [
    {
        label: "Booked",
        value: "2"
    },
    {
        label: "Claim",
        value: "1"
    },
    {
        label: "Not Booked",
        value: '5'
    }
]

export const surveyRegisterOptions = [
    {
        label: "Bing",
        value: "bing",
    },
    {
        label: "SMS invite",
        value: "sms_invite",
    },
    {
        label: "Called by a rep",
        value: "called_by_a_rep",
    },
    {
        label: "eMail invite",
        value: "email_invite",
    },
    {
        label: "Voicemail invite",
        value: "voicemail_invite",
    },
    {
        label: "Referred by a colleague",
        value: "reffered_by_a_colleague",
    },
    {
        label: "Google",
        value: "google",
    },
    {
        label: "Other",
        value: "other"
    }
]
export const surveyCmsOptions = [
    {
        label: "Hubspot",
        value: "hubspot",
    },
    {
        label: "JobNimbus",
        value: "jobnimbus",
    },
    {
        label: "Jobber",
        value: "jobber",
    },
    {
        label: "pipedrive",
        value: "pripedrive",
    },
    {
        label: "insightly",
        value: "insightly",
    },
    {
        label: "Zoho",
        value: "zoho",
    },
    {
        label: "FollowupCRM",
        value: "followupcrm",
    },
    {
        label: "AccuLynx",
        value: "acculynx",
    },
    {
        label: "excel",
        value: "excel",
    },
    {
        label: "Other",
        value: "other"
    }
]

export const disposedLeadSurveyOptions = [
    {
        label: 'Consumer is unreachable',
        value: 'consumer_is_unreachable'
    },
    {
        label: 'Consumer already found someone to comple the work',
        value: 'consumer_already_found_someone_to_complete_the_work'
    },
    {
        label: 'Agreement could not be reached due to price or capacity',
        value: 'agreement_could_not_be_reached_due_to_price_or_capacity'
    },
    {
        label: 'The lead was submitted under the wrong job type and the correct job type is not in your profile',
        value: 'the_lead_was_submitted_under_the_wrong_job_type_and_the_correct_job_type_is_not_in_your_profile'
    },
    {
        label: 'The lead was submitted with the wrong zip code and the correct zip code does not match a zip code you are profiled for',
        value: 'the_lead_was_submitted_with_the_wrong_zip_code_and_the_correct_zip_code_does_not_match_a_zip_code_you_are_profiled_for'
    },
    {
        label: 'Other',
        value: 'other'
    },
]

export const usStates = [
    { label: 'ALABAMA', value: 'AL'},
    { label: 'ALASKA', value: 'AK'},
    { label: 'AMERICAN SAMOA', value: 'AS'},
    { label: 'ARIZONA', value: 'AZ'},
    { label: 'ARKANSAS', value: 'AR'},
    { label: 'CALIFORNIA', value: 'CA'},
    { label: 'COLORADO', value: 'CO'},
    { label: 'CONNECTICUT', value: 'CT'},
    { label: 'DELAWARE', value: 'DE'},
    { label: 'DISTRICT OF COLUMBIA', value: 'DC'},
    { label: 'FEDERATED STATES OF MICRONESIA', value: 'FM'},
    { label: 'FLORIDA', value: 'FL'},
    { label: 'GEORGIA', value: 'GA'},
    { label: 'GUAM', value: 'GU'},
    { label: 'HAWAII', value: 'HI'},
    { label: 'IDAHO', value: 'ID'},
    { label: 'ILLINOIS', value: 'IL'},
    { label: 'INDIANA', value: 'IN'},
    { label: 'IOWA', value: 'IA'},
    { label: 'KANSAS', value: 'KS'},
    { label: 'KENTUCKY', value: 'KY'},
    { label: 'LOUISIANA', value: 'LA'},
    { label: 'MAINE', value: 'ME'},
    { label: 'MARSHALL ISLANDS', value: 'MH'},
    { label: 'MARYLAND', value: 'MD'},
    { label: 'MASSACHUSETTS', value: 'MA'},
    { label: 'MICHIGAN', value: 'MI'},
    { label: 'MINNESOTA', value: 'MN'},
    { label: 'MISSISSIPPI', value: 'MS'},
    { label: 'MISSOURI', value: 'MO'},
    { label: 'MONTANA', value: 'MT'},
    { label: 'NEBRASKA', value: 'NE'},
    { label: 'NEVADA', value: 'NV'},
    { label: 'NEW HAMPSHIRE', value: 'NH'},
    { label: 'NEW JERSEY', value: 'NJ'},
    { label: 'NEW MEXICO', value: 'NM'},
    { label: 'NEW YORK', value: 'NY'},
    { label: 'NORTH CAROLINA', value: 'NC'},
    { label: 'NORTH DAKOTA', value: 'ND'},
    { label: 'NORTHERN MARIANA ISLANDS', value: 'MP'},
    { label: 'OHIO', value: 'OH'},
    { label: 'OKLAHOMA', value: 'OK'},
    { label: 'OREGON', value: 'OR'},
    { label: 'PALAU', value: 'PW'},
    { label: 'PENNSYLVANIA', value: 'PA'},
    { label: 'PUERTO RICO', value: 'PR'},
    { label: 'RHODE ISLAND', value: 'RI'},
    { label: 'SOUTH CAROLINA', value: 'SC'},
    { label: 'SOUTH DAKOTA', value: 'SD'},
    { label: 'TENNESSEE', value: 'TN'},
    { label: 'TEXAS', value: 'TX'},
    { label: 'UTAH', value: 'UT'},
    { label: 'VERMONT', value: 'VT'},
    { label: 'VIRGIN ISLANDS', value: 'VI'},
    { label: 'VIRGINIA', value: 'VA'},
    { label: 'WASHINGTON', value: 'WA'},
    { label: 'WEST VIRGINIA', value: 'WV'},
    { label: 'WISCONSIN', value: 'WI'},
    { label: 'WYOMING', value: 'WY' }
]