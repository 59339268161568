import React, { useState, useEffect} from "react";
import { Button, Modal } from "react-bootstrap";
import { useJsApiLoader, Autocomplete} from '@react-google-maps/api';
import { getUserCompanyInfo, updateUserCompanyInfo } from "../../api/userApi";
import Skeleton from "react-loading-skeleton";

export const CompanyProfile = () => {
    const [show, setShow] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    const [dbaName, setDBA] = useState("");
    const [invalidDbaName, setInvalidDbaName] = useState(false)
    const [streetAddress, setAddress] = useState('')
    const [invalidStreetAddress, setInvalidStreetAddress] = useState(false);
    const [employeeCount, setEmployeeCount] = useState('')
    const [invalidEmployeeCount, setInvalidEmployeeCount] = useState(false)
    const [companyAge, SetCompanyAge] = useState('')
    const [invalidCompanyAge, setInvalidCompanyAge] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null)
    
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBxAEFQuErKxlK8PJPAsdkLeOoHHwcBd_o',
        libraries: ['places']
    })

    const loadAutocomplete = (autocmplete) => {
        setAutocomplete(autocmplete)
    }
  
    const onPlaceChanged = () => {
        if (autocomplete) setAddress(autocomplete.getPlace().formatted_address)
    }

    const fillInputs = (info) => {
        setDBA(info.dbaName);
        setAddress(info.streetAddress);
        setEmployeeCount(info.employeeCount);
        SetCompanyAge(info.companyAge);
    }

    const getCompanyInfo = async() => {
        setLoadingData(true);
        const data = await getUserCompanyInfo();
        fillInputs(data.data);
        setLoadingData(false);
    }                                                                                                                                                                                             

    const handleSubmit = async() => {
        const data = {
           dbaName,
           streetAddress,
           employeeCount,
           companyAge,
        }
        await updateUserCompanyInfo(data);
    }

    useEffect(() => {
        getCompanyInfo();
    }, [])
    
    const handleUpdate = () => {
        handleSubmit();
        getCompanyInfo();
        setShow(false);
    }
    
    const handleHide = () => {
        setShow(false);
    }

    if (!isLoaded) {
        return (
            <div className='container-fluid'>
                <Skeleton height={60}/>
                <div className='row'>
                    <div className='col'>
                        <Skeleton height={"100vh"} />
                    </div>
                    <div className='col'>
                        <Skeleton height={"100vh"} />
                    </div>
                </div>
            </div>
        );
    }

        

    return (
        <>
              {
                loadingData?
                <Skeleton count={7} height='60px'/>
                :
                <div>
                <div className="mb-3">
                    <label className="Company Name">Company Name</label>
                    <input className="form-control" placeholder="Company Name" value={dbaName} onChange={e => setDBA(e.target.value)}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Company Address</label>
                    <Autocomplete onLoad={loadAutocomplete} onPlaceChanged={onPlaceChanged} restrictions={{"country": "US"}}>
                        <input placeholder={streetAddress} className="form-control"/>
                    </Autocomplete>
                </div>
                <div className="mb-3">
                    <label className="form-label">Company Employees</label>
                    <input className="form-control" value={employeeCount} onChange={e => setEmployeeCount(e.target.value)}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Years In Business</label>
                    <input className="form-control" value={companyAge} onChange={e => SetCompanyAge(e.target.value)}/>
                </div>
               
                <Button variant="dark" size="lg" onClick={() => setShow(true)}>
                    Edit Company
                </Button>
            </div>
            }
            <Modal show={show} onHide={() => handleHide()}>
                <Modal.Header>Confirm Update</Modal.Header>
                <Modal.Footer>
                    <Button variant='dark' onClick={() => handleUpdate()}>Yes</Button>
                    <Button variant='dark' onClick={() => setShow(false)}>No</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CompanyProfile;