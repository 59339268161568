import React, { useState, useEffect } from "react"
import httpClient from "../../config/httpClient";
import { BaseApi, BaseFront } from "../../config";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBInput
} from 'mdb-react-ui-kit';
import logo from "../../assets/images/logo.svg";
import { Link } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';


export const Login = ({leadID, leadIcon, leadIDTitle}) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  const [invalidLogin, setInvalidLogin] = useState(false)

  const auth = useAuth()
  const navigate = useNavigate()
  const logInUser = async () => {
    try {
      const resp = await httpClient.post(`${BaseApi}/login/`, {
        email,
        password,
      });
      if (resp.data.error) return
      const token = resp.data.token
      localStorage.setItem("token_user", token)
      auth.login(resp.data);
      httpClient.defaults.headers.common["x-access-tokens"] = token
      const response = await httpClient.get(`${BaseApi}/@me`)
      localStorage.setItem("user", JSON.stringify(response.data));

      const leadIDParam = searchParams.get("leadID")
      if (leadIDParam) return window.location.href = `${BaseFront}/leadboard/?leadID=${leadID}`;
      localStorage.setItem("leadClaimed", JSON.stringify({leadID, leadIcon, leadIDTitle}))
      // navigate('/', {replace: true})
      window.location.href = `${BaseFront}/leadboard/`;
    } catch (error) {
      if (error.response.status === 401) {
        setInvalidLogin(true)
      }
    }
  };

   useEffect(() => {
    setInvalidLogin(false)
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();

        logInUser()
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [email, password])

  const handleRegister = () => {
    localStorage.setItem("leadClaimed", JSON.stringify({leadID, leadIcon, leadIDTitle}))
    navigate("/register1", {replace: true})
  }
  return (
    <MDBContainer className="my-5" style={{height: '70vh', overflowY: 'hidden'}}>

    <MDBCard>
      <MDBRow className='g-0'>
        <MDBCol>
          <MDBCardBody className='d-flex flex-column'>

            <div className='d-flex flex-row mt-2'>
              <img

                src={logo}
                style={{height: "15vh"}}
                width = "100%"
                className="d-inline-block align-top login-logo-size"
                alt="Lead Depot Logo"
              />
            </div>

            <h5 className="fw-normal my-1 pb-md-2" style={{letterSpacing: '1px'}}>Sign into your account</h5>

              {
                invalidLogin? 
                <form className="needs-validation was-validated">
                <div>
                  <div className='form-outline'>
                    <input 
                      id="invalid" 
                      className='form-control active form-control-lg' 
                      style={{border: "1.5px solid #dc3545"}}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      
                    />
                    <label className="form-label" for="invalid" style={{color:"#dc3545", backgroundColor: "white", paddingInline: "4px"}}>Email</label>
                    <div style={{ display: 'block', position: 'relative', zIndex: 2}} class="invalid-feedback">
                      This email doesn't exist or doesn't match with this password
                    </div>

                  </div>
                </div>

                </form>
                :
                <MDBInput value={email} wrapperClass='mb-4'  label='Email address' id='formControlUN' type='email' size="lg" onChange={e => setEmail(e.target.value)}/>
              }
              { invalidLogin?
                <form className="needs-validation was-validated">
                <div>
                  <div className='form-outline'>
                    <input 
                      id="invalid" 
                      className='form-control active form-control-lg' 
                      style={{border: "1.5px solid #dc3545"}}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                    />
                    <label className="form-label" for="invalid" style={{color:"#dc3545", backgroundColor: "white", paddingInline: "4px"}}>Password </label>
                    <div style={{ display: 'block', position: 'relative'}} class="invalid-feedback">
                      Password doesn't match with this account
                    </div>

                  </div>
                </div>

                </form>
                :
                <MDBInput value={password} wrapperClass='mb-4' label='Password' id='formControlPW' type='password' size="lg" onChange={e => setPassword(e.target.value)}/>
              }

            <MDBBtn onClick={logInUser} className="mb-4 px-5" color='dark' size='lg'>Login</MDBBtn>
            <a className="small text-muted" href="#!">Forgot password?</a>
            <p className="mb-md-2 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <span onClick={() => handleRegister()}  style={{color: '#393f81', cursor: "pointer", textDecoration: "underline", fontWeight: "bold"}}>Register here</span></p>

            <div className='d-flex flex-row justify-content-start'>
              <a href="https://leaddepot.com/terms-and-conditions/" target="_blank"  className="small text-muted me-1">Terms of use.</a>
              <a href="https://leaddepot.com/privacy-policy/" target="_blank" className="small text-muted">Privacy policy</a>
            </div>

          </MDBCardBody>
        </MDBCol>

      </MDBRow>
    </MDBCard>

  </MDBContainer>
  );

}

export default Login;