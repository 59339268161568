import { encryptData } from "./encrypt"


export const isValidZip = (zipcode) =>  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode)
export const emptyString = (str) =>  str.length === 0 && typeof str == 'string'
export const isValidEmail = (email) => new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}').test(email)

export const validateUserRegistrations = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    const valid = {}
    if (user.acceptedTNC){
        localStorage.setItem(
        'acceptedTNC', 
        encryptData(`acceptedTNC/${user.id}`))
            valid.acceptedTNC = true;
    }
    else localStorage.setItem('acceptedTNC', 'acceptedTNC')
    if (user.companyRegistered){ 
        localStorage.setItem(
        'companyRegistered', 
        encryptData(`companyRegistered/${user.id}`))
            valid.companyRegistered = true
    }
    else localStorage.setItem('companyRegistered', 'companyRegistered')
    return valid
}