import React, { useState, useEffect } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import LeadsIcon from "../LeadsIcons";
import { updateLeadClaim, setLeadClaim} from "../../api/leadsApi";
import { MDBIcon } from "mdb-react-ui-kit";
import { statusOptions } from "../../helpers/constantData";
import { delay } from "lodash";
import Confirm from "./Confirm";
export const MyLeadsModal = ({leadID, leadInfo, leadIcon, leadIDTitle, closedLeads, loadingLead, show, setShow, getActiveLeads}) => {
    
    const [newStatus, setNewStatus] = useState('');
    const [transactionUpdate, setTransactionUpdate] = useState(false)
    const [loadLead, setLoadLead] = useState(false);
    const [leadStatus, setLeadsStatus]  = useState(null);
   
    const handleHide = () => {
        setShow(false);
        setNewStatus('');
        setTransactionUpdate(false);
        localStorage.removeItem("MyLead")
        console.log("hiding")
        setLoadLead(false)
        setLeadsStatus(null)
    }

    const getStatusLabel = (status) => {
        if (status) return statusOptions.filter(option => option.value == status)[0].label
        return ''
    }
    
    const confirmUpdate = async () => {
        const data = {
            leadID,
            transactionTypeID: newStatus
        }
        await updateLeadClaim(data);
        getActiveLeads();
        handleHide();
    }

    const handleStatusChange = async (status, survey, surveyDescription) => {
        if (status =='1') return alert("You can't change status to claim")
        setLoadLead(true)
        const newLeadData = {
            leadID: leadInfo.leadID,
            verticalID: leadInfo.verticalID,
            transactionTypeID: parseInt(status),
            amount: parseFloat(leadInfo.referralFee.split("$")[1]),
            priceID: leadInfo.priceID
        }
        if (status == '4') {
            newLeadData.surveyOption = survey
            if (survey == "other") newLeadData.surveyOptionDescription = surveyDescription
        }
        await setLeadClaim(newLeadData);    
        setTransactionUpdate(true)
        handleHide();
        getActiveLeads();
        
    }
    

    
    return <>
        <Modal size="lg" show={show} onHide={() => handleHide()}>
                    <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">{
                        leadStatus?
                        'Confirmation'
                        :
                        transactionUpdate? 
                        'Confirmation '
                        :<>   
                            <LeadsIcon width="40px" height="50px" icon={leadIcon} />
                            <span className='ms-2'> Lead Details - {leadIDTitle}</span> 
                        </>
                    }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { 
                        leadStatus?
                        <Confirm
                            leadStatus={leadStatus}
                            handleStatusChange={handleStatusChange}
                         />
                        :    
                        transactionUpdate?
                        <>
                        <Modal.Body>
                            <div className='d-flex flex-column align-items justify-content-center'>
                                <div className='d-flex flex-column justify-content center align-items-center mb-4 gap-1'>
                                    <span>Update the status of lead </span>
                                    <span><span className='fw-bold'>{leadInfo? leadInfo.leadID : null}</span> to </span>
                                    <span className='ms-1 fw-bold'>
                                        {getStatusLabel(newStatus)}
                                    </span>
                                </div>
                                <Button variant='dark' onClick={() => confirmUpdate()} >Confirm</Button>
                            </div>
                        </Modal.Body>
                        </>
                        :
                            !loadingLead? 
                        <>
                        <div style={{height: '50vh', overflowY: 'auto'}}>
                        <Table  style={{position: 'sticky', top: 0}}>
                                <tbody>
                                    <tr>
                                        <td><b>Days To Disposition:</b></td>
                                        <td>
                                        {
                                            leadInfo? 
                                            leadInfo.transactionDate == 1? `${leadInfo.transactionDate} Day` : `${leadInfo.transactionDate} Days`
                                            : 
                                            null
                                        }
                                    
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Service Type:</b></td>
                                        <td>{leadInfo? leadInfo.verticalName : null}</td>
                                    </tr>
                                    {
                                        closedLeads?
                                        <tr>
                                            <td><b>Job Closed Fee:</b></td>
                                            <td>{leadInfo? leadInfo.referralFee : null}</td>
                                        </tr>
                                        :
                                        null
                                    }
                                    <tr>
                                        <td><b>Name:</b></td>
                                        <td>{leadInfo? `${leadInfo.firstName} ${leadInfo.lastName}` : null}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Phone Number:</b></td>
                                        <td>{leadInfo? 
                                            <>
                                                <span style={{marginRight: "3px"}}>{leadInfo.phoneNumber }</span>

                                                <span  onClick={() => {window.open(`tel:${leadInfo.phoneNumber}`)}} className='me-2' style={{cursor: "pointer"}}><MDBIcon fas icon="phone-alt" /></span>
                                            </>
                                            
                                            : null}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Email Address:</b></td>
                                        <td>{leadInfo? leadInfo.emailAddress : null}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Lead Address:</b></td>
                                        <td>{leadInfo? `${leadInfo.leadAddress}, ${leadInfo.city}, ${leadInfo.usState}, ${leadInfo.zipcode}` : null}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Comments:</b></td>
                                        <td>{leadInfo? leadInfo.comments : null}</td>
                                    </tr>
                                    {
                                        closedLeads?
                                        <tr>
                                            <td><b>Transaction Status:</b></td>
                                            <td><b>{leadInfo? leadInfo.leadStatus : null}</b></td>
                                        </tr>
                                        :
                                        null
                                    }
                                </tbody>
                                
                                
                            
                        </Table>
                        </div>
                        {
                            !closedLeads?
                            <div className='d-flex justify-content-center align-items-center mt-2 flex-column gap-2'>
                                <span><b>Update Lead Status:</b></span>
                                <div className='d-flex flex-row justify-content-center align-items-center gap-5 mb-2    '>
                                    <Button disabled={loadLead} variant="success" size="lg" onClick={() => setLeadsStatus('2')}>
                                        Booked
                                    </Button>
                                    <Button disabled={loadLead} variant='danger' size="lg" onClick={() => setLeadsStatus('4')}>
                                        Return
                                    </Button>
                                </div>
                            </div>
                            :
                            null
                        }
                        <Modal.Footer>
                        
                    
                        </Modal.Footer>
                        </>
                        :
                        <Skeleton count={5} height="50px" />
                        }
                        
                                                             
                    </Modal.Body>
                   
                    
        </Modal>
    </>
}

export default MyLeadsModal;