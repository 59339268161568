import axios from "axios"; 

const httpClient = axios.create({
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  httpClient.interceptors.request.use(
    config => {
      const token = localStorage.getItem("token_user");
  
      if (token) {
        config.headers['x-access-tokens'] = token;
      }
      
      return config;
    },
  
    error => Promise.reject(error)
  );

  export default httpClient;