import React, { useState, useEffect} from 'react';
import { getPaymentMethodsList, deletePaymentMethod, changeDefaultPaymentMethod, authorizePaymentCards } from '../../api/billingApi';
import { Modal, Table } from 'react-bootstrap';
import { MDBIcon, MDBBadge, MDBBtn, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import LeadsLoader from '../LeadsLoader';
import AddingPaymentMethod from './AddingPaymentMethod';
import Amex from '../../assets/images/amex.svg';
import Diners from '../../assets/images/diners.svg';
import Discover from '../../assets/images/discover.svg';
import Jcb from '../../assets/images/jcb.svg';
import Mastercard from '../../assets/images/mastercard.svg';
import Visa from '../../assets/images/visa.svg';
import Unknown from '../../assets/images/unknown.svg';
import './manageBilling.css'

export const ManageBilling = ({ refreshCards, setRefreshCards}) => {

    const [show, setShow] = useState(false)
    const [cards, setCards] = useState([])
    const [defaultMethod, setDefaultMethod] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openForDeleted, setOpenForDeleted] = useState(null)
    const [deletePaymentID, setDeletePaymentID] = useState(null)
    const [unAuthorized, setUnAuthorized] = useState(false)

    const cardsIcons = {
        'visa': Visa,
        'diners': Diners,
        'discover': Discover,
        'mastercard': Mastercard,
        'amex': Amex,
        'jcb': Jcb,
        'unknown': Unknown
    }

    
    const getCards = async () => {
        setLoading(true)
        const response = await getPaymentMethodsList();
        const defaultMethodCard = response.cards.filter(({id}) => id == response.default_method_id)
        setCards([...defaultMethodCard, ...response.cards.filter(({id}) => id !== response.default_method_id )])
        setDefaultMethod(response.default_method_id)
        setLoading(false)
    }

    const handleDelete = async () => {
        await deletePaymentMethod(deletePaymentID)
        handleCloseConfirm()
        getCards()
        
    }

    const handleCloseConfirm = () => {
        setShow(false)
        setDeletePaymentID(null)
        localStorage.removeItem('authorize')
    }
    
    const handleConfirmDelete = (card, paymentMethodID) => {
        setDeletePaymentID(paymentMethodID)
        if (cards.length == 1) {
            setOpenForDeleted({...card, paymentMethodID}) 
            return
        }
        else setDeletePaymentID(paymentMethodID)
        setShow(true)
    }

    useEffect(() => {   
        if (localStorage.getItem('authorize')) {
            if (localStorage.getItem('authorize') == 'false') {
                setUnAuthorized(true)
                setShow(true)
            } else localStorage.removeItem('authorize')
        }
        
        else getCards();
    }, [])

    useEffect(() => {
        if(refreshCards) {
            getCards()
            setRefreshCards(false)
        }
    }, [refreshCards])

    const changePaymentMethod = async (paymentID) => {
        await changeDefaultPaymentMethod({paymentID});
        getCards();
    }
    

    return (<>
        {
            loading?
            <LeadsLoader />
            :
            <div className='d-flex flex-column'>
                <AddingPaymentMethod getCards={getCards} openForDeleted={openForDeleted} />
                <Table className='table-manage-billing-responsive'>
                    <tbody>
                        {
                            cards.map(({card, id}) => (
                                <tr key={`card-${id}`}>
                                    <td>
                                    {
                                        cardsIcons[card.brand]? 
                                            <img style={{width: '48px', height: '48px', marginRight: '1rem'}} src={cardsIcons[card.brand]} /> 
                                            :
                                            <img style={{width: '48px', height: '48px', marginRight: '1rem'}} src={cardsIcons.unknown} />
                                    }
                                    </td>
                                    <td style={{verticalAlign: 'middle'}}>
                                        {`${card.brand} •••• ${card.last4}`}  
                                    </td>      
                                         
                                    <td style={{verticalAlign: 'middle'}} className='d-none d-md-table-cell'>
                                    {
                                        id == defaultMethod ?
                                        <MDBBadge className='text-dark me-2' color='light' light>
                                            Default method
                                        </MDBBadge>
                                        :
                                        <MDBBadge 
                                            className='me-2' 
                                            style={{cursor: 'pointer',backgroundColor: '#fbf0da',color: '#73510d'}} 
                                            color='warning' light
                                            onClick={() => changePaymentMethod(id)}
                                        >
                                            Set as default method
                                        </MDBBadge>
                                    }
                                    </td>
                                    <td style={{verticalAlign: 'middle'}} className='d-none d-md-table-cell'>
                                        <div className='col d-flex align-items-center'>
                                            <MDBIcon onClick={() => handleConfirmDelete(card, id)} style={{cursor: 'pointer', marginRight: '1rem'}} fas icon="times-circle" />
                                            <span>It expires at {`${card.exp_month}/${card.exp_year}.`}</span>
                                        </div>
                                    </td>
                                    
                                    <td style={{verticalAlign: 'middle'}} className='d-table-cell d-md-none'>
                                    <MDBDropdown dropleft group className='shadow-0'>
                                        <MDBDropdownToggle color='light'><MDBIcon fas icon="ellipsis-h" /></MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                        <MDBDropdownItem link className='d-flex justify-content-center align-items-center'> 
                                        {
                                            id == defaultMethod ?
                                            <MDBBadge className='text-dark d-flex justify-content-center align-items-center' color='light' light>
                                                Default method
                                            </MDBBadge>
                                            :
                                            <MDBBadge 
                                                className='me-2 d-flex justify-content-center align-items-center' 
                                                style={{cursor: 'pointer',backgroundColor: '#fbf0da',color: '#73510d'}} 
                                                color='warning' light
                                                onClick={() => changePaymentMethod(id)}
                                            >
                                                Set as default method
                                            </MDBBadge>
                                        }
                                        </MDBDropdownItem>
                                        <MDBDropdownItem className='d-flex justify-content-center align-items-center' link>
                                            <MDBBadge onClick={() => handleConfirmDelete(card, id)} style={{cursor: 'pointer'}} className='mx-2' color='danger' light>
                                                Remove payment method
                                            </MDBBadge>
                                        </MDBDropdownItem>
                                        
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>

        }
        <Modal show={show} onHide={() => handleCloseConfirm()}>
            {
                unAuthorized?
                <>
                    <Modal.Header closedButton>
                        <h1>Card Authorization Failed</h1>
                    </Modal.Header>
                    <Modal.Body>
                    <div className='d-flex flex-column'>
                        <p><b>A valid method of payment is required to use this platform. Lead Depot will place a temporary authorization for the price of the lead on your card:</b></p>
                        <ul>
                            <li>This authorization will only happen on your first claim</li>
                            <li>The authorization is not a charge</li>
                            <li>(Mon-Fri) the funds will be released immediately</li>
                            <li>Weekend authorizations will be released the following business day</li>
                        </ul>
                        <br/>
                            <MDBBtn color="dark" size="lg" className="align-self-center justify-self-center" onClick={() => setShow(false)}>
                                Try Again
                            </MDBBtn>
                    </div>
                    </Modal.Body>
                </>
                :
                <>

                    <Modal.Header>
                        Confirm delete
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                            <span className='mb-2'>Are you sure do you want to delete this payment method?</span>
                            <div className='d-flex gap-3'>
                                <MDBBtn onClick={() => handleCloseConfirm()} color='dark'>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn onClick={() => handleDelete()} color='dark'>
                                    Confirm
                                </MDBBtn>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            }
        </Modal>
    </>)
}

export default ManageBilling;