import React, {useState, useEffect} from "react"
import { Modal, Card, Button, Table, ModalBody } from "react-bootstrap";
import { authorizePayment } from "../../api/billingApi";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setLeadClaim } from "../../api/leadsApi";
import LeadsIcon from "../LeadsIcons";
import moment from "moment"
import Skeleton from "react-loading-skeleton";
import SetupForm from "../SetupForm";
import httpClient from "../../config/httpClient";
import { BaseApi, StripeKey } from "../../config";
import { useNavigate } from 'react-router-dom';
import LeadsLoader from "../LeadsLoader";


const stripePromise = loadStripe(StripeKey);
export const ClaimLeadModal = ({leadInfo, getLeadsData, leadIcon, leadIDTitle, show, setShow, loadingLead, isLogged, claimCount, unAuthorized, setUnAuthorized, billingFromAd, setBillingFromAd}) => {
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [previousClaim, setPreviousClaim] = useState(false);
    const [acceptAgreement, setAcceptAgreement] = useState(false)
    const [loadClaim, setLoadClaim] = useState(false);
    const [cardOnFile, setFile] = useState(false);
    const [stripeOptions, setOptions] = useState(null);
    const [stripePrompt, setPrompt] = useState(false);
    const [settingClaim, setSettingClaim] = useState(false);
    const navigate = useNavigate()

    const settingPreviousClaim = async () => {
        const priceID = localStorage.getItem('priceIDforCardRecentConfirmed')
        console.log(priceID)
        
        setLoadClaim(true)
        if (priceID) {
            const authorize = await authorizePayment({priceID});
            if (!authorize.authorize) {
            setPreviousClaim(false)
            setLoadClaim(false)
            setFile(false);
            setLoadClaim(false)
            localStorage.removeItem('leadClaimedToSuccess')
            localStorage.removeItem('priceIDforCardRecentConfirmed')
            return
            }
        }
        setPreviousClaim(true)
        const data = {leadID: leadInfo.leadID, verticalID: leadInfo.verticalID, transactionTypeID: 1, amount: null}
        await setLeadClaim(data)
        setLoadClaim(false)
        getOptions();
        localStorage.setItem("MyLead", JSON.stringify({leadID: leadInfo.leadID, leadIcon: leadInfo.iconName, leadIDTitle: leadInfo.leadID.slice(0,5) , leadDate: new Date()}))
        getLeadsData();
    }

    const handleClaim = async () => {
        setLoadClaim(true)
        if (cardOnFile) {
            const data = {leadID: leadInfo.leadID, verticalID: leadInfo.verticalID, transactionTypeID: 1, amount: null}
            await setLeadClaim(data)
            setLoadClaim(false)
            getOptions();
            localStorage.setItem("MyLead", JSON.stringify({leadID: leadInfo.leadID, leadIcon, leadIDTitle, leadDate: new Date()}))
            await getLeadsData();
        } else {
            getOptions();
            localStorage.setItem("leadClaimedToSuccess", JSON.stringify({leadID: leadInfo.leadID, leadIcon, leadIDTitle, verticalID: leadInfo.verticalID}))
            setSettingClaim(false)
        }
    }

    useEffect(() => {
       if(settingClaim) handleClaim();
    }, [settingClaim])

    useEffect(() => {
        if (billingFromAd) getOptions()
    }, [billingFromAd])
    
    useEffect(() => {
        if(isLogged) getCheckcard();
        if (localStorage.getItem("leadClaimedToSuccess")) setPreviousClaim(true)
    }, [])

    useEffect(() => {
        if (leadInfo && previousClaim) settingPreviousClaim()
    }, [leadInfo])
    
    useEffect(() => {

    }, [unAuthorized])

    const handleShow = () => setShow(true);
    const showStripe = () => setPrompt(true);
    const hideStripe = () => setPrompt(false);

    const getCheckcard = async () => {
        try {
            const resp = await httpClient.get(`${BaseApi}/payment/checkcard/`)
             setFile(resp.data.card)
        } catch (error) {
            console.log("There was an issue connecting to the server");
        }
    }


    const getOptions = async () => {
        try {
            const resp = await httpClient.post(`${BaseApi}/payment/addcard/`)
             setOptions(
                {
                    // passing the client secret obtained in step 2
                    clientSecret: resp.data["client_secret"],
                    // Fully customizable with appearance API.
                    appearance: {/*...*/},
                  }
                
             )

             showStripe()
            } catch (error) {
              console.log("There was an issue connecting to the server");
            }
    }

    const handleHide = () => {
        setShow(false);
        setPrompt(false);
        setAcceptAgreement(false);
        setAcceptTerms(false);
        setSettingClaim(false);
        setPreviousClaim(false)
        setUnAuthorized(false)
        setBillingFromAd(false)
        setLoadClaim(false)
        if(cardOnFile) {
            localStorage.removeItem("leadClaimed")
            localStorage.removeItem("notification_lead")
        }
        else if (leadInfo && leadInfo.leadID) localStorage.setItem("leadClaimedToSuccess", JSON.stringify({leadID: leadInfo.leadID, leadIcon, leadIDTitle, verticalID: leadInfo.verticalID}))
        if (localStorage.getItem("leadClaimedToSuccess")) localStorage.removeItem("leadClaimedToSuccess")
        localStorage.removeItem("MyLead")
        localStorage.removeItem("howItWorksVideo")
    }

    const handleSeeMyLeads = () => {
        localStorage.removeItem("leadClaimed")
        localStorage.removeItem("notification_lead")
        if (localStorage.getItem("leadClaimedToSuccess")) localStorage.removeItem("leadClaimedToSuccess")
        navigate("/myleads")
    }


    return (
        <Modal size="lg" show={show} onHide={() => handleHide()}>
        {  
            billingFromAd?
                <>
                    <Modal.Header>
                        Powered by Stripe
                    </Modal.Header>
                    <Modal.Body>
                    {
                        stripePrompt?
                        <Elements stripe={stripePromise} options={stripeOptions}>
                            <SetupForm priceID={''} updateBilling={true} />
                        </Elements>
                        :
                        null
                    }
                    </Modal.Body>
                </>
            :
            unAuthorized?
            <>
                <Modal.Header closedButton>
                    <h1>Card Authorization Failed</h1>
                </Modal.Header>
                <Modal.Body>
                <div className='d-flex flex-column'>
                    <p><b>A valid method of payment is required to use this platform. Lead Depot will place a temporary authorization for the price of the lead on your card:</b></p>
                    <ul>
                        <li>This authorization will only happen on your first claim</li>
                        <li>The authorization is not a charge</li>
                        <li>(Mon-Fri) the funds will be released immediately</li>
                        <li>Weekend authorizations will be released the following business day</li>
                    </ul>
                    <br/>
                        <Button disabled={loadingLead} variant="dark" size="lg" className="align-self-center justify-self-center" onClick={() => setUnAuthorized(false)}>
                            Try Again
                        </Button>
                </div>
                </Modal.Body>
            </>
            :
            localStorage.getItem("howItWorksVideo")?
            <>
            <Modal.Header closeButton>
                <h1>How it works</h1>
            </Modal.Header>
            <Modal.Body >
                <div className="video-container">

            <iframe width="560" height="315" src="https://www.youtube.com/embed/rHaI83dWXSU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Modal.Body>
            </>
            :
            previousClaim?
            !loadingLead && !loadClaim?
                (
                    <Modal.Body>
                        <Modal.Header className='d-flex justify-content-center align-items-center h1'>
                            Lead claimed!
                        </Modal.Header>
                        <div className='d-flex justify-content-center align-items-center flex-column'>

                            <span className='leadboard-success-icon'><i className='fas fa-check-circle'></i></span>
                            <span className='lead-board-success-description'>Thank you, you lead has been claimed and is now available under the My Leads tab</span>
                            <div className='d-flex justify-content-center align-items-center gap-5'>
                                <Button variant="dark" size="lg" onClick={() => handleHide()}>
                                    Get more leads
                                </Button>

                                <Button variant="success" size="lg" onClick={() => handleSeeMyLeads()}>
                                    See My Lead
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                )
                :
                (
                    <>
                        <LeadsLoader />
                    </>
                )
            :
            !stripePrompt?
            
            !acceptAgreement?
            <>
            <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">            
                <LeadsIcon
                    icon={leadIcon}
                    width={"40px"}
                    height={"40px"}
                />
                <span className="ms-2"> Lead Details - {leadIDTitle}</span> 
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loadingLead ? 
                <>
                <div style={{height: '50vh', overflowY: 'auto'}}>
                    <Table>
                            <tbody>
                                <tr>
                                    <td><b>Lead Age:</b></td>
                                    <td>
                                       { 
                                        leadInfo? 
                                        `${parseInt(moment().diff(moment(leadInfo.leadDate), "days"))} ${parseInt(moment().diff(moment(leadInfo.leadDate), "days")) == 1? "Day": "Days"}` 
                                        : 
                                        null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Service Type:</b></td>
                                    <td>{leadInfo? leadInfo.verticalName : null}</td>
                                </tr>
                                <tr>
                                    <td><b>Location:</b></td>
                                    <td>{leadInfo? leadInfo.city +','+' '+leadInfo.usState+','+' '+leadInfo.zipcode : null}</td>
                                </tr>
                                <tr>
                                    <td><b>Comments:</b></td>
                                    <td>{leadInfo? leadInfo.comments : null}</td>
                                </tr>
                            </tbody>
                    </Table>
                    <Card className="mask-custom">
                      <Card.Body>
                        {/* <p className="blue lead-title">Lead</p><p className="lead-title"><b>Depot</b> estimated job value: <b>{leadInfo? leadInfo.estimatedValue : null}</b></p> */}
                        <p>Job Closed Fee:&nbsp;<b>{leadInfo? leadInfo.referralFee : null}</b></p>
                      </Card.Body>
                    </Card>
                </div>
                </>
                :
                <Skeleton count={5} height="50px" />
                }
                <br/>
                <div className= "d-grid gap-2">
                    {
                        leadInfo?
                        leadInfo.isClaimed? 
                        <Button disabled variant="dark" size = "lg" >
                            Is already claimed
                        </Button>
                        :  
                        <Button disabled={claimCount == 0 || JSON.parse(localStorage.getItem("user")).accountStatus == 2} variant="dark" size = "lg" onClick={() => setAcceptAgreement(true)}>
                            Claim
                        </Button>
                        :
                        null
                    } 
                   
                </div>
            </Modal.Body>
            <Modal.Footer>
                
            
            </Modal.Footer>
            </>
        :
        <>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
            <center><h5><b>Hey there, before you get started working that lead, lets make sure we're on the same page!</b></h5></center>
                <Card
                bg={'Light'}
                style={{height:'40vh',overflowY : 'scroll',borderColor : '#000'}}
                >
                    <Card.Body>
                        <Card.Text>
                            {
                                cardOnFile?
                                null
                                :
                                <>
                                    <p><b>Lead Depot will place a temporary authorization for the price of the lead on your card:</b></p>
                                    <ul>
                                        <li>This authorization will only happen on your first claim</li>
                                        <li>The authorization is not a charge</li>
                                        <li>(Mon-Fri) the funds will be released immediately</li>
                                        <li>Weekend authorizations will be released the following business day</li>
                                    </ul>
                                    <br/>
                                </>
                            }
                                <>
                                    <p><b>LeadDepot will not charge you for this lead if:</b></p>
                                    <ul>
                                        <li>You update the lead status to <b>not booked</b> before {moment().add("days", 14).format("MM/DD/YYYY")}</li>
                                    </ul>
                                    <br/>  
                                </>
                            <p><b>LeadDepot will only charge you {leadInfo? leadInfo.referralFee: null} if either:</b></p>
                            <ul>
                                <li>You update the lead status to <b>booked</b></li>
                            
                                <li>You do <b>not</b> update the lead status at all before {moment().add("days", 14).format("MM/DD/YYYY")}</li>
                            </ul>  
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br/>
                <div className="form-check my-1">
                    <input className="form-check-input"  disabled={acceptTerms} type="checkbox" onChange={() => setAcceptTerms(!acceptTerms)} id="flexCheckDefault"/>
                    <label className="form-check-label" for="flexCheckDefault">
                        <b>I accept </b>the above terms
                    </label>
                </div>
                {
                    loadClaim?
                    <Button disabled style={{width:"100%"}} variant="dark" size="lg">
                        ACCEPT TERMS
                    </Button>
                    :
                    <Button disabled={settingClaim && !loadClaim? true : !acceptTerms} style={{width:"100%"}} variant="dark" size="lg" onClick={() => setSettingClaim(true)}>
                        ACCEPT TERMS
                    </Button>                        
                }
        </Modal.Body>                        
        </>
        :
            <Modal.Body>
                {
                    !cardOnFile? 
                        (   <>
                                <Modal.Header>
                                    Powered by Stripe
                                </Modal.Header>
                                <Elements stripe={stripePromise} options={stripeOptions}>
                                    <SetupForm priceID={leadInfo.priceID} updateBilling={null} />
                                </Elements>
                            </>
                        ) 
                        :
                        (
                            !loadClaim?
                            (
                                <>
                                    <Modal.Header className='d-flex justify-content-center align-items-center h1'>
                                        Lead claimed!
                                    </Modal.Header>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>

                                        <span className='leadboard-success-icon'><i className='fas fa-check-circle'></i></span>
                                        <span className='lead-board-success-description'>Thank you, you lead has been claimed and is now available under the My Leads tab</span>
                                        <div className='d-flex justify-content-center align-items-center gap-5'>
                                            <Button variant="dark" size="lg" onClick={() => handleHide()}>
                                                Get more leads
                                            </Button>

                                            <Button variant="success" size="lg" onClick={() => handleSeeMyLeads()}>
                                                See My Lead
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <span>Loading</span>
                                </>
                            )
                        )
                    }
            </Modal.Body>
        }
        </Modal>
    )
}

export default ClaimLeadModal;