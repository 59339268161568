import React, {useState, useEffect} from "react"
import { Modal, Card, Button, Table } from "react-bootstrap";
import Login from "./Login";
import LeadsIcon from "../LeadsIcons";
import moment from "moment"
import Skeleton from "react-loading-skeleton";

export const ClaimLeadUnLoggedModal = ({leadInfo, leadIcon, leadIDTitle, show, setShow, loadingLead}) => { 
    const [login, setLogin] = useState(false);  

    const handleClaim = async () => {
       setLogin(true)
    }

    const handleHide = () => {
        setShow(false);
        setLogin(false);
    }


    return (
        <Modal size="lg" show={show} onHide={() => handleHide()}>
        {   !login?
            <>
            <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
                    <LeadsIcon
                        icon={leadIcon}
                        width={"40px"}
                        height={"40px"}
                        />                    
                <span className="ms-2"> Lead Details - {leadIDTitle}</span> 
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loadingLead ? 
                <>
                <div style={{height: '50vh', overflowY: 'auto'}}>
                    <Table>
                            <tbody>
                                <tr>
                                    <td><b>Lead Age:</b></td>
                                    <td>
                                       { 
                                        leadInfo? 
                                        `${parseInt(moment().diff(moment(leadInfo.leadDate), "days"))} ${parseInt(moment().diff(moment(leadInfo.leadDate), "days")) == 1? "Day": "Days"}` 
                                        : 
                                        null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Service Type:</b></td>
                                    <td>{leadInfo? leadInfo.verticalName : null}</td>
                                </tr>
                                <tr>
                                    <td><b>Location:</b></td>
                                    <td>{leadInfo? leadInfo.city +','+' '+leadInfo.usState+','+' '+leadInfo.zipcode : null}</td>
                                </tr>
                                <tr>
                                    <td><b>Comments:</b></td>
                                    <td>{leadInfo? leadInfo.comments : null}</td>
                                </tr>
                            </tbody>
                    </Table>
                    <Card className="mask-custom">
                      <Card.Body>
                        {/* <p className="blue lead-title">Lead</p><p className="lead-title"><b>Depot</b> estimated job value: <b>{leadInfo? leadInfo.estimatedValue : null}</b></p> */}
                        <p>Job Closed Fee:&nbsp;<b>{leadInfo? leadInfo.referralFee : null}</b></p>
                      </Card.Body>
                    </Card>
                </div>
                </>
                :
                <Skeleton count={5} height="50px" />
                }
                <br/>
                <div className= "d-grid gap-2">
                    <Button variant="dark" size = "lg" onClick={() => handleClaim()}>
                        Claim
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
            </>
            :
            leadInfo? <Login leadID={leadInfo.leadID} leadIcon={leadIcon} leadIDTitle={leadIDTitle} /> : null
        }
        </Modal>
    )
}

export default ClaimLeadUnLoggedModal;