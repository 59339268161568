import React, { useState, useEffect } from "react";
import { getUserInfo, editUserInfo } from "../../api/userApi";
import { getVerticalGroupOptions } from "../../api/verticalApi";
import { Button,Modal } from "react-bootstrap"
import { inputRange } from "../../helpers/inputHelpers";
import { Multiselect } from "../../components/Multiselect";
import ValidateInput from "../ValidateInputs";
import { isValidEmail, emptyString } from "../../helpers/validationHelpers";
import { usStates } from "../../helpers/constantData";
import LeadsLoader from "../LeadsLoader";
import httpClient from "../../config/httpClient";
import { BaseApi } from "../../config";
import Skeleton from "react-loading-skeleton";
import { formatPhoneNumber, getNumbersFromString } from "../../helpers/inputHelpers";

export const UserProfile = () => {
    const [show, setShow] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [options, setOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loading,setLoading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [verticals, setVerticals] = useState([]);
    const [verticalsInfo, setVerticalsInfo] = useState("");
    const [states, setStates ] = useState([])
    const [email, setEmail] = useState("");
    const [contactFN, setContactFN] = useState("");
    const [contactLN, setContactLN] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [radius, setRadius] = useState("");
    const [subscribeEmail, setSubscribeEmail] = useState(false);
    const [subscribePhone, setSubscribePhone] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);

    const fillInputs = (info) => {
        setEmail(info.email);
        setContactFN(info.contactFN)
        setContactLN(info.contactLN)
        setPhoneNumber(info.phoneNumber)
        setZipCode(info.searchZip)
        setRadius(info.radius)
        setVerticalsInfo(info.verticals)
        setStates(info.statesLicensed? usStates.filter(state => {
            const userLicensedStates = info.statesLicensed.split(',').filter( st => st == state.value)
            if (userLicensedStates.length) return true
            else return false
        }) : [])
        setSubscribeEmail(info.subscribe_email)
        setSubscribePhone(info.subscribe_phone)
    }

    const handleZipcode =  (e) => {
        if (e.target.value.length > 5) return;
        setZipCode(e.target.value);
    }

    const getUserData = async () => {
        setLoading(true)
        const data = await getUserInfo();
        fillInputs(data);
        setLoading(false)
    }
    
    useEffect(() => {
        setLoadingData(true);
        setLoadingOptions(true);
        (async () => {
            setLoadingOptions(true);
            const data = await getVerticalGroupOptions()
            setOptions(data.data.map(option => ({
                label: option.groupName,
                value: option.groupID
            })))
            setLoadingOptions(false);
        })()
        getUserData();
        setLoadingData(false);
    }, [])

    useEffect(() => {
        if (verticalsInfo && options.length) {
            const verticalsSelected = [];
            verticalsInfo.split(",").map(vertical => {
                const verticalArray = options.filter(v => v.value == vertical)
                if (verticalArray.length) verticalsSelected.push(verticalArray[0])
            })
            setVerticals(verticalsSelected)
            setLoadingOptions(false);
        }
    }, [verticalsInfo, options])

    const handleSubmit = async() => {
        console.log(await setInvalidations())
        if (await setInvalidations()) return;
        const data = {
            email,
            contactFN,
            contactLN,
            phoneNumber,
            searchZip: zipCode,
            radius,
            verticals: verticals.map(v => v.value).join(","),
            statesLicensed: states.length? states.map(state => state.value).join(',') : null,
            subscribePhone: subscribePhone? 1 : 0,
            subscribeEmail: subscribeEmail? 1 : 0
        }
        await editUserInfo(data);
        const response = await httpClient.get(`${BaseApi}/@me`)
      

        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("zip", zipCode)
        localStorage.setItem('radius', radius)
    }

    const setInvalidations = async () => {
        if (emptyString(email) || emptyString(phoneNumber) || !isValidEmail(email)) {
            console.log('invalid')
            console.log(email)
            console.log(phoneNumber)
            console.log(isValidEmail(email))
            if (!isValidEmail(email)) setInvalidEmail(true)
            return true
        }
        return await verifyPhoneNumber()
    }

    const verifyPhoneNumber = async () => {
        if (JSON.parse(localStorage.getItem('user')).phoneNumber !== phoneNumber) return false
        const response = await httpClient.post(`${BaseApi}/verify_phone_number/`, {
            phoneNumber: formatPhoneNumber(phoneNumber),
        })
        if(response.data.error) {
        setInvalidPhoneNumber(true)
        setLoadingUpdate(false)
        return true
        } else return false
    }

    const handleUpdate = () => {
        handleSubmit();
        getUserInfo();
        setShow(false);
    }
    const handleHide = () => {
        setShow(false);
    }

    const handlePhone = (phone) => {
        if (getNumbersFromString(phone).length > 10) return
        setPhoneNumber(phone)
        setInvalidPhoneNumber(false);
    }

    const handleEmail = (mail) => {
        setEmail(mail);
        setInvalidEmail(false);
    }

    const handleRadius = (e) => {
        const rad = e.target.value
        if(parseInt(rad) <=0) return setRadius(1);
        if (parseInt(rad) > 1000) alert('The maximum radius is 1000 miles')
        setRadius(inputRange(rad, 0, 1000))
    }

    return (
        <>
              {
                loadingData || loading?
                <LeadsLoader />
                :
                <div>
                <div className="mb-3">
                        <ValidateInput
                            value={email}
                            onChange={handleEmail}
                            invalid={invalidEmail}
                            label="Email"
                            type="email"
                            feedback="Please provide a valid email address"
                        />
                </div>
                <div className="mb-3">
                    <label className="form-label">First Name</label>
                    <input className="form-control" value={contactFN} onChange={e => setContactFN(e.target.value)}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Last Name</label>
                    <input className="form-control" value={contactLN} onChange={e => setContactLN(e.target.value)}/>
                </div>
                    <div className="mb-3">
                        <ValidateInput
                            value={phoneNumber}
                            onChange={handlePhone}
                            invalid={invalidPhoneNumber}
                            label='Mobile Phone'
                            feedback="Please provide a valid phone number"
                        />
                
                   
                </div>
                <div className ='mb-3'>
                    <label className="form-labem">Notification Settings</label>
                    <div className="d-flex gap-4">
                        <div className="d-flex gap-2">
                            <input type='checkbox' className='form-check' checked={subscribeEmail} onClick={() => setSubscribeEmail(!subscribeEmail)} />
                            <label class="form-check-label">
                                Email
                            </label>
                            </div>
                        <div className="d-flex gap-2">
                            <input type='checkbox' className='form-check' checked={subscribePhone} onClick={() => setSubscribePhone(!subscribePhone)} />
                            <label class="form-check-label">
                                Phone Number
                            </label>
                        </div>

                    </div>

                </div>
                <div className="mb-3">
                    <label className="form-label">Zip Code</label>
                    <input className="form-control" value={zipCode} onChange={handleZipcode}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Radius</label>
                    <input className="form-control" value={radius} onChange={handleRadius} />
                </div>
                <div className="mb-3">
                    {
                        loadingOptions?
                        <Skeleton height={50} width={"100%"}/>
                        :
                        <>
                            <label className="form-label">Services Provided</label>
                            <Multiselect value={verticals} options={options} onChange={setVerticals}/>
                        </>
                    }
                </div>
                <div className="mb-3">
                    
                        <>
                            <label className="form-label">States Served</label>
                            <Multiselect value={states} options={usStates} onChange={setStates} displayMessage='State'/>
                        </>
                </div>
                
                <Button variant="dark" disabled={loadingUpdate} size="lg" onClick={() => setShow(true)}>
                    Edit Profile
                </Button>
            </div>
            }
            <Modal show={show} onHide={() => handleHide()}>
                <Modal.Header>Confirm Update</Modal.Header>
                <Modal.Footer>
                    <Button variant='dark' onClick={() => handleUpdate()}>Yes</Button>
                    <Button variant='dark' onClick={() => setShow(false)}>No</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserProfile;