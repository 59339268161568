import React from "react";
import { MDBContainer, MDBCard, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
//import { useNavigate } from "react-router-dom";
import { BaseFront } from "../../config";
import logo from "../../assets/images/logo.svg"
export const InvalidToken = () => {
    const goToRecover = () => {
        window.location.href=`${BaseFront}/recover_password`
    }
    return (
        <MDBContainer className="my-5">
            <MDBCard className="py-5">
            <div className='d-flex flex-column mt-2 justify-content-center align-items-center'>
              <img
                src={logo}
                width = "100%"
                className="d-inline-block align-top login-logo-size my-3"
                alt="Lead Depot Logo"
              />
                <MDBIcon  color="danger" fas icon="times-circle" size="7x" />
                <span className="my-5">The current token is invalid, send the recover email password again</span>
                <MDBBtn onClick={() => goToRecover()}> Recover password</MDBBtn>
            </div>
            </MDBCard>
        </MDBContainer>
    )
}

export default InvalidToken;