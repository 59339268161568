import React, { useState, useEffect } from 'react';
import {Row, Col, Container, Modal } from 'react-bootstrap';
import { BaseApi, GoogleMapApiKey, BaseFront } from '../../config';
import { useSearchParams } from "react-router-dom";
import { getUserInfo } from '../../api/userApi';
import { authorizePaymentUpdateBilling } from '../../api/billingApi';
import httpClient from '../../config/httpClient';
import { useJsApiLoader, GoogleMap, Circle  } from '@react-google-maps/api';
import { milesToMeter } from '../../helpers/unitConversionHelpers';
import { sortOptions } from '../../helpers/constantData';
import { sortLeadAgeAsc, sortLeadAgeDesc, sortLeadDistAsc, sortLeadDistDesc } from '../../helpers/sortingHelpers'; 
import { authorizePayment } from '../../api/billingApi';
import { getLeadBoardDetail, getPublicLeads, getLeadsClaimLimit, getLeadBoardPublicDetail, getLead } from '../../api/leadsApi'
import { getVerticalGroupOptions } from '../../api/verticalApi';
import { useStripe } from '@stripe/react-stripe-js';
import FilterLeadBoard from '../../components/FilterLeadBoard';
import LeadBoardTable from '../../components/LeadBoardTable';
import ClaimLeadModal from '../../components/ClaimLeadModal';
import ClaimLeadUnLoggedModal from '../../components/ClaimLeadUnLoggedModal';
import LeadsLoader from '../../components/LeadsLoader';
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'
import 'react-loading-skeleton/dist/skeleton.css'
import './LeadBoard.css';


import moment from 'moment';

export const LeadBoard = () => {
    const [open, setOpen] = useState(false)
    const [message, setMessage ] = useState('')
    const [loadingAuthorization, setLoadingAuthorization] = useState(false)
    const [show, setShow] = useState(false)
    const [googleMap, setGoogleMap] = useState(/**@type google.maps.Map*/(null));
    const [claimCount, setClaimCount] = useState(5);
    const [center, setCenter] = useState({});
    const [perimeter, setPerimeter] = useState(false);
    const [zipcode, setZipcode] = useState('');
    const [radius, setRadius] = useState(null);
    const [verticals, setVerticals] = useState([]);
    const [defaultLoad, setDefaultLoad] = useState(false);
    const [defaultLoadMap, setDefaultLoadMap] = useState(false);
    const [previousClaimFirstLoad, setPreviousClaimFirstLoad] = useState(false)
    const [selectVertical, setSelectVerticals] = useState([]);
    const [verticalGroupOptions, setVerticalGroupOptions] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState(false);
    const [leadInfo, setLeadInfo] = useState(null);
    const [leadIDTitle, setLeadIDTitle] = useState("");
    const [leadIcon, setLeadIcon] = useState("");
    const [loadingLead , setLoadingLead] = useState(false);
    const [leads, setLeads] = useState([]);
    const [sort, setSort] = useState(sortOptions.dist.asc)
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLogged, setIsLogged] = useState(true)    
    const [unAuthorized, setUnAuthorized] = useState(false)
    const [billingFromAd,setBillingFromAd] = useState(false)
    const handleShow = () => setShow(true);
    const stripe = useStripe();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GoogleMapApiKey,
        libraries: ['places']
    })

    const setUserValues = async () => {
        const zipcodeParam = searchParams.get("zip");
        const radiusParam = searchParams.get("radius");
        const verticalsParam = searchParams.get("verticals");
        const leadIDParam = searchParams.get("leadID")
        if (!localStorage.getItem("user") && !localStorage.getItem('token_user')) {
            setIsLogged(false);
            if (!leadIDParam && !radiusParam && !zipcodeParam && !verticalsParam)
            window.location.href = `${BaseFront}/login/`
            setRadius(radiusParam);
            setZipcode(zipcodeParam)
            localStorage.setItem("radius", radiusParam)
            localStorage.setItem("zip", zipcodeParam)
            localStorage.setItem("verticals", verticalsParam)
            settingVerticalGroups()
            return setLeadBoardUnLogged()
        }
        const user = JSON.parse(localStorage.getItem("user"))
        if (zipcodeParam && zipcodeParam.length == 5) {
            localStorage.setItem("zip", zipcodeParam);
            setZipcode(zipcodeParam)
        }
        else if (localStorage.getItem("zip") && localStorage.getItem("zip") !== 'null') setZipcode(localStorage.getItem("zip"));
        else setZipcode(user.searchZip);
        if (radiusParam) {
            localStorage.setItem("radius", radiusParam);
            setRadius(radiusParam);
        }
        else if (localStorage.getItem("radius") && localStorage.getItem("radius") !== 'null') setRadius(localStorage.getItem("radius"))
        else setRadius(user.radius);
        if (verticalsParam) localStorage.setItem("verticals", verticalsParam);
        else if (!localStorage.getItem("verticals") || localStorage.getItem("verticals") == 'null') localStorage.setItem("verticals", user.verticals)
        settingVerticalGroups()
        setDefaultLoad(true);
    }

    const settingVerticalGroups = async () => {
        const response = await getVerticalGroupOptions();
        setVerticalGroupOptions(response.data.map(vert => ({
            label: vert.groupName,
            value: vert.groupID
        })))
        const selectVertsID = localStorage.getItem("verticals").split(",")
        const selectVert = []
        selectVertsID.map(vert => {
            const selectVerticalGroup = response.data.filter(ver => ver.groupID == vert)
            if (selectVerticalGroup.length) selectVert.push({
                label: selectVerticalGroup[0].groupName, 
                value: selectVerticalGroup[0].groupID
            })
        })
        setSelectVerticals(selectVert)
    }  

    const setClaimLimit = async () => {
        const response = await getLeadsClaimLimit();
        setClaimCount(response.claims)
    }

    const setLeadBoardUnLogged = async () => {
        setLoadingLeads(true)
       const data = {
        Radius: radius? radius : searchParams.get("radius"),
        Zipcode: zipcode? zipcode : searchParams.get("zip"),
        Verticals: selectVertical.length? selectVertical.map(vert => vert.value).join(",") : searchParams.get("verticals"),
       }
       if (!radius) setRadius(searchParams.get("radius"))
       if (!zipcode) setZipcode(searchParams.get("zip"))
       
       const resp = await getPublicLeads(data);
       if (resp.error) return
       const leadsData = resp.data.map((ld) => {
            ld = {
                ...ld, 
                leadDate: parseInt(moment().diff(moment(ld.leadDate), "days")), 
                icon: 'data:image/png;base64,' + ld.icon
            }
            const disable = parseInt(ld.leadDate) >= 30
            return {...ld, disable}
        })
        setLeads([
            ...leadsData.filter(leadRecord => !leadRecord.disable),
            ...leadsData.filter(leadRecord => leadRecord.disable),
        ])
        setVerticals(resp.verticalData)
        setLoadingLeads(false)
    }

    useEffect(() => {
        if(!stripe) {
            return;
        }
        handleStatus();
    }, [stripe])

    useEffect(() => {
        if(defaultLoad) getLeadsData();
    }, [defaultLoad])

    useEffect(() => {
        if(perimeter && zipcode && !defaultLoadMap) {
            handleAddress();
            setDefaultLoadMap(true);
        }
    }, [perimeter, zipcode])

    useEffect(() => {
        if(!show) setLeadInfo(null)
    }, [show])

    useEffect(() => {
        const leadsAble = sortingData(leads.filter(lead => !lead.disable))
        const leadsDisable = sortingData(leads.filter(lead => lead.disable))
        setLeads([...leadsAble, ...leadsDisable])
    }, [sort])

    const handleStatus = async () => {
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
          );
           if (!clientSecret) return
          console.log(clientSecret);
          // Retrieve the SetupIntent
          const setupIntent = await stripe.retrieveSetupIntent(clientSecret)
          switch (setupIntent.setupIntent.status) {
            case 'succeeded':
              setMessage('Success! Your payment method has been saved.');
              break;
              
              case 'processing':
                setMessage("Processing payment details. We'll update you when processing is complete.");
                break;
    
            case 'requires_payment_method':
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                setMessage('Failed to process payment details. Please try another payment method.');
                break;
            }
            if (localStorage.getItem('updateBillingStatus')) {
              await authorizePaymentUpdateBilling()
              localStorage.removeItem('updateBillingStatus')
              const response = await getUserInfo();
              console.log(response)
              localStorage.setItem('user', JSON.stringify(response))
              setLoadingAuthorization(false)
              setOpen(false);
            }
            
          }

    const sortingData = (data) => {
        if (sort == sortOptions.age.desc) data.sort(sortLeadAgeDesc)
        if (sort == sortOptions.age.asc) data.sort(sortLeadAgeAsc)
        if (sort == sortOptions.dist.asc) data.sort(sortLeadDistAsc)
        if (sort == sortOptions.dist.desc) data.sort(sortLeadDistDesc)
        return data
    }

    const getLeadsData = async () => {
        try {
            setLoadingLeads(true)
            const data = {
                VerticalGroups:  selectVertical.length? 
                    selectVertical.map(vert => vert.value).join(",") 
                    : 
                    localStorage.getItem("verticals ")?
                    localStorage.getItem("verticals")
                    :
                    "",
                Radius: parseInt(radius),
                Zipcode: zipcode,
            }
            const resp = await httpClient.post(`${BaseApi}/leads/data/`, data)
            if (resp.data.error) return
            const leadsData = resp.data.data.map((ld) => {
                ld = {
                    ...ld, 
                    leadDate: parseInt(moment().diff(moment(ld.leadDate), "days")), 
                    icon: 'data:image/png;base64,' + ld.icon
                }
                const disable = parseInt(ld.leadDate) >= 30
                return {...ld, disable}
            })
            setLeads([
                ...leadsData.filter(leadRecord => !leadRecord.disable),
                ...leadsData.filter(leadRecord => leadRecord.disable),
            ])
            setVerticals(resp.data.verticalData)
            setLoadingLeads(false)
        } catch (error) {
            console.log(error)
            console.log("There was an issue connecting to the server");
            setLoadingLeads(false)
        }
    }

    const setDefaultLead = async () => {
        setLoadingLead(true)
        const leadClaimed = JSON.parse(localStorage.getItem("leadClaimed"));
        const response = await getLeadBoardDetail(leadClaimed.leadID);
        setLeadIDTitle(leadClaimed.leadIDTitle);
        setLeadIcon(leadClaimed.leadIcon)
        setShow(true)
        setLeadInfo(response.data)
        setLoadingLead(false);
    }  

    const setDefaultLeadByParam = async () => {
        const leadID = searchParams.get("leadID") ? 
        searchParams.get("leadID"):
        localStorage.getItem("leadClaimed")?
        JSON.parse(localStorage.getItem("leadClaimed")).leadID
        :
        localStorage.getItem("notification_lead")
        const response = await getLeadBoardDetail(leadID);
        setLeadIDTitle(leadID.slice(0,5));
        setLeadIcon(response.data.iconName)
        setShow(true)
        setLeadInfo(response.data)
        setLoadingLead(false)
    }
    
    const setDefaultLeadByPreviuousClaim = async () => {
        setPreviousClaimFirstLoad(true)
        const leadID = JSON.parse(localStorage.getItem("leadClaimedToSuccess")).leadID
        console.log(leadID)
        const response = await getLeadBoardDetail(leadID);
        const priceID = localStorage.getItem('priceIDforCardRecentConfirmed')
        const authorization = await authorizePayment({priceID});
        if (!authorization.authorize) setUnAuthorized(true)
        setShow(true)
        setLeadInfo(response.data)
        setLeadIcon(response.data.iconName)
        setLoadingLead(false)
        await getLeadsData();
    }

      const handleBilling = () => {
        setBillingFromAd(true);
        setShow(true);
    }

    useEffect(() => {
        if (localStorage.getItem('updateBillingStatus')) {
            setOpen(true)
            setLoadingAuthorization(true)
        }
        if(localStorage.getItem("howItWorksVideo")) setShow(true)
        setUserValues();
        setClaimLimit();
        if (searchParams.get("leadID") && !localStorage.getItem("leadClaimedToSuccess") || localStorage.getItem("notification_lead")) setDefaultLeadByParam();
        if (localStorage.getItem("leadClaimed") && !localStorage.getItem("leadClaimedToSuccess")) setDefaultLead()
    }, [])

    useEffect(() => {
        if (localStorage.getItem("leadClaimedToSuccess") && claimCount <= 5 && !previousClaimFirstLoad) setDefaultLeadByPreviuousClaim()
    }, [claimCount])
    
    const onLoad = React.useCallback((map) => {;
        setGoogleMap(map);
        const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({
                'address': zipcode,
                "componentRestrictions":{"country":"US"}
            }, (results, status) => {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    const position = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    }
                    map.panTo(position)
                    setCenter(position)
                    setPerimeter(true);
                ;
            } else {
                    alert("Geocode was not successful for the following reason: " + status);
            }
            })
      }, [])
      
    const handleAddress = () => {
        const geocoder = new window.google.maps.Geocoder();
        setPerimeter(false);
        geocoder.geocode({
            'address': zipcode,
            "componentRestrictions":{"country":"US"}
        }, (results, status) => {
            if (status == window.google.maps.GeocoderStatus.OK) {
                const position = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                }
                googleMap.panTo(position)
                googleMap.setCenter(position)
                setCenter(() => position)
                setPerimeter(true);
        } else {
                alert("Geocode was not successful for the following reason: " + status);
        }
        })

    }

    const handleDetails = async(lead) => {
        setLoadingLead(true)
        const {leadID, iconName} = lead 
        setLeadIDTitle(leadID.slice(-5));
        setLeadIcon(iconName);
        handleShow()
        const response = localStorage.getItem("user")? await getLeadBoardDetail(leadID) : await getLeadBoardPublicDetail(leadID)
        setLeadInfo(response.data)
        setLoadingLead(false);
    }

    if (!isLoaded) {
        return (
            <div className='container-fluid'>
                <Skeleton height={"100vh"} />
            </div>
        );
    }

        return (
        <>    
            <Container fluid className='overflow-hidden overflow-md-auto'>
                <FilterLeadBoard 
                    radius={radius}
                    setRadius={setRadius}
                    zipcode={zipcode}
                    setZipcode={setZipcode}
                    selectVertical={selectVertical}
                    setSelectVerticals={setSelectVerticals}
                    perimeter={perimeter}
                    verticalGroupOptions={verticalGroupOptions}
                    isLogged={isLogged}
                    getLeadsData={getLeadsData}
                    setLeadBoardUnLogged={setLeadBoardUnLogged}
                    handleAddress={handleAddress}
                />
                <div className='btn-danger row account-status-2' style={{display: loadingAuthorization? 'none'  : localStorage.getItem('user')? JSON.parse(localStorage.getItem('user')).accountStatus == 2? '' : 'none' : 'none'}}>
                    <div className='col account-status-2-font'>
                        Your account has been paused due to non-payment, <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleBilling()}>click here to update your billing details.</span>
                    </div>
                </div>
                <Row>
                    <Col className={`${localStorage.getItem('user')? JSON.parse(localStorage.getItem('user')).accountStatus == 2? 'MapBox-userType2' : 'MapBox' : 'MapBox'} d-none d-lg-block`}>
                        <GoogleMap
                            center={center}
                            zoom={9}
                            mapContainerStyle={{ width: '100%', height: '100%'}}
                            onLoad={onLoad}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false
                            }}>
                            {
                                perimeter? 
                                    <Circle 
                                        center={center}
                                        options={{
                                            strokeColor: '#FF0000',
                                            strokeOpacity: 0.8,
                                            strokeWeight: 2,
                                            fillColor: '#FF0000',
                                            fillOpacity: 0.35,
                                            clickable: false,
                                            draggable: false,
                                            editable: false,
                                            visible: true,
                                            radius: milesToMeter(radius),
                                            zIndex: 1
                                        }}
                                    />
                                    :
                                    null
                            }
                        </GoogleMap>
                    
                    </Col>
                    <Col className={`${localStorage.getItem('user')? JSON.parse(localStorage.getItem('user')).accountStatus == 2? 'tableColumn-userType2' : 'tableColumn' : 'tableColumn'} tableColumn col-lg-9`} style={{overflowY: 'scroll'}}> 
                            {
                                loadingAuthorization?
                                <LeadsLoader />
                                :
                                loadingLeads? 
                            (
                                <>
                                <LeadsLoader />
                                </>
                            )
                            :
                            (   
                                <>
                                    <LeadBoardTable 
                                        leads={leads} 
                                        verticals={verticals} 
                                        sort={sort}
                                        handleDetails={handleDetails} 
                                        setLeads={setLeads} 
                                        setSort={setSort} />
                                </>
                            )}
                    </Col>
                </Row>
            </Container>
            <Modal show={open}>
                <Modal.Body>
                    <LeadsLoader />
                    <span className='text-center d-flex align-items-center justify-content-center'>
                    Please do not navigate away while we authorize your card, doing so may result in a duplicate authorization.
                    </span>
                </Modal.Body>
            </Modal>
            {
                isLogged?
                <ClaimLeadModal 
                    loadingLead={loadingLead} 
                    leadIcon={leadIcon}
                    leadIDTitle={leadIDTitle} 
                    leadInfo={leadInfo}
                    claimCount={claimCount}
                    getLeadsData={getLeadsData} 
                    show={show} 
                    setShow={setShow} 
                    isLogged={isLogged}
                    unAuthorized={unAuthorized}
                    setUnAuthorized={setUnAuthorized}
                    billingFromAd={billingFromAd}
                    setBillingFromAd={setBillingFromAd}
                />
                :
                <ClaimLeadUnLoggedModal
                    loadingLead={loadingLead} 
                    leadIcon={leadIcon}  
                    leadIDTitle={leadIDTitle} 
                    leadInfo={leadInfo}  
                    show={show} 
                    setShow={setShow}
                />
            }
        </>
        );
}

export default LeadBoard;