import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from '../SetupForm';
import httpClient from '../../config/httpClient';
import { StripeKey, BaseApi } from '../../config';
const stripePromise = loadStripe(StripeKey);
export const AddingPaymentMethod = ({getCards, openForDeleted}) => {
    const [show, setShow] = useState(false)
    const [stripeOptions, setOptions] = useState(null);
    const [stripePrompt, setPrompt] = useState(false);
    const showStripe = () => setPrompt(true);
    
    const handleHide = () => {
        setShow(false)
    }

    useEffect(() => {
        getOptions();
    }, [])

    useEffect(() => {
       if(openForDeleted) setShow(true);
    }, [openForDeleted])
    
    const getOptions = async () => {
        try {
            const resp = await httpClient.post(`${BaseApi}/payment/addcard/`)
             setOptions(
                {
                    // passing the client secret obtained in step 2
                    clientSecret: resp.data["client_secret"],
                    // Fully customizable with appearance API.
                    appearance: {/*...*/},
                  }
                
             )

             showStripe()
            } catch (error) {
              console.log("There was an issue connecting to the server");
            }
    }

    return (
        <>
            <div onClick={() => setShow(true)} style={{cursor: 'pointer', borderBottom: '2px solid #a7a6a6b8'}} className='d-flex justify-content-end align-items-center gap-2'>
                <span className='fw-bold'>Add Payment Method</span>
                <MDBIcon fas icon="plus" className='me-1' />
            </div>
            <Modal size='lg' show={show} onHide={() => handleHide()}> 
                <Modal.Header>
                    Powered by Stripe
                </Modal.Header>
                <Modal.Body>
                    {
                        stripePrompt?
                        <Elements stripe={stripePromise} options={stripeOptions}>
                            <SetupForm priceID={''} managePaymentMethod={{openForDeleted}} />
                        </Elements>
                        :
                        null
                    }
                    </Modal.Body>
            </Modal>
        </>
    )
};

export default AddingPaymentMethod;