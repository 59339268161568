import httpClient from "../config/httpClient";
import { BaseApi } from "../config";

export const getBillingDetails = async () => {
    try {
        const resp = await httpClient.get(`${BaseApi}/payment/manage/`)
        return resp.data;
    } catch (e) {
        console.error(e)
    }
    return null
} 

export const getPaymentMethodsList = async () => {
    try {
        const resp = await httpClient.get(`${BaseApi}/payment/payment_methods/`)
        return resp.data;
    } catch (e) {
        console.error(e)
    }
    return null
} 
export const deletePaymentMethod = async (paymentID) => {
    try {
        const resp = await httpClient.delete(`${BaseApi}/payment/payment_methods/?payment_id=${paymentID}`)
        return resp.data;
    } catch (e) {
        console.error(e)
    }
    return null
} 

export const authorizePayment = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/payment/authorize_card/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}

export const authorizePaymentUpdateBilling = async () => {
    try {
        const response = await httpClient.get(`${BaseApi}/payment/update_billing/`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}

export const authorizePaymentCards= async () => {
    try {
        const response = await httpClient.get(`${BaseApi}/payment/authorize_by_manage_payments/`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}

export const AuthorizeDefaultDeleting = async () => {
    try {
        const response = await httpClient.get(`${BaseApi}/payment/authorize_deleting_default/`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}

export const changeDefaultPaymentMethod = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/payment/change_payment_method/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}